export const defaultTranslations = {
  'shared.professional': 'Professional',
  'shared.basic': 'Basic',
  'shared.trial': 'Trial',
  'shared.beta': 'Beta',
  'shared.create': 'Create',
  'shared.update': 'Update',
  'shared.email': 'Email',
  'shared.password': 'Password',
  'shared.map': 'Map',
  'shared.table': 'Table',
  'shared.admin': 'Admin',
  'shared.advisorSolution': 'Advisor Solution',
  'shared.status': 'Status',
  'shared.score': 'Score',
  'shared.showDetails': 'Show details',
  'shared.indicators': 'Indicators',
  'shared.compositeIndicators': 'Composite Indicators',
  'shared.indicator': 'Indicator',
  'shared.bullish': 'Bullish',
  'shared.bearish': 'Bearish',
  'shared.positive': 'Positive',
  'shared.neutral': 'Neutral',
  'shared.neutralScore': 'Neutral score',
  'shared.negative': 'Negative',
  'shared.save': 'Save',
  'shared.auth0': 'Auth0',
  'shared.cancel': 'Cancel',
  'shared.confirm': 'Confirm',
  'shared.change': 'Change',
  'shared.dataset': 'Dataset',
  'shared.ticker': 'Ticker',
  'shared.name': 'Name',
  'shared.account': 'Account',
  'shared.isin': 'ISIN',
  'shared.edit': 'Edit',
  'shared.done': 'Done',
  'shared.delete': 'Delete',
  'shared.takeOver': 'Take over',
  'shared.yes': 'Yes',
  'shared.no': 'No',
  'shared.areYouSure': 'Are you sure?',
  'shared.notApplicable': 'N/A',
  'shared.updated': 'Updated',
  'shared.current': 'Current',
  'shared.projected': 'Projected',
  'shared.threshold': 'Threshold',
  'shared.processing': 'Processing',
  'shared.processingFailed': 'Processing failed',
  'shared.model': 'Model',
  'shared.mode': 'Mode',
  'shared.benchmark': 'Benchmark',
  'shared.objective': 'Objective',
  'shared.lastValue': 'Last value',
  'shared.lastUpdate': 'Last update',
  'shared.transformation': 'Transformation',
  'shared.movingAverage': 'Moving average',
  'shared.movingAverageDescription':
    'Method to smooth out short-term fluctuations and highlight longer-term trends or cycles.',
  'shared.days': 'Days',
  'shared.rateOfChange': 'Rate of change',
  'shared.rateOfChangeDescription':
    'Computation of the percentage change of an indicator over a defined period. This is a way of estimating trend strength or momentum.',
  'shared.shift': 'Shift',
  'shared.shiftDescription':
    'Testing if the indicator can be perceived as a leading indicator, by shifting the transformed indicator into the future for selected period.',
  'shared.compareToAverage': 'Compare to average',
  'shared.compareToAverageDescription':
    'Produces a stationary variable by comparing the current value of an indicator to its historical average.',
  'shared.inverse': 'Inverse',
  'shared.inverseDescription':
    'Inverting the transformation. Allows you to make contrarian indicators',
  'shared.calculate': 'Calculate',
  'shared.saveResults': 'Save results',
  'shared.auto': 'Auto',
  'shared.manual': 'Manual',
  'shared.autoShort': 'A',
  'shared.manualShort': 'M',
  'shared.statistics': 'Statistics',
  'shared.period': 'Period',
  'shared.f1Score': 'F1 score',
  'shared.f1ScoreDescription_1':
    'F1 score is measure of a test’s accuracy. It is measured as the harmonic average of precision and recall.',
  'shared.f1ScoreDescription_2': 'Where:',
  'shared.f1ScoreDescription_3':
    'Precision = True Bear / (True Bear + False Bear)',
  'shared.f1ScoreDescription_4':
    'Recall = True Bear / (True Bear + False Bull)',
  'shared.actual': 'Actual',
  'shared.predicted': 'Predicted',
  'shared.bear': 'Bear',
  'shared.bull': 'Bull',
  'shared.bearScore': 'Bear score',
  'shared.bullScore': 'Bull score',
  'shared.inverted': 'Inverted',
  'shared.included': 'Included',
  'shared.all': 'All',
  'shared.add': 'Add',
  'shared.factor': 'Factor',
  'shared.factors': 'Factors',
  'shared.instrument': 'Instrument',
  'shared.instruments': 'Instruments',
  'shared.total': 'Total',
  'shared.totalWeighted': 'Total weighted',
  'shared.category': 'Category',
  'shared.equities': 'Equities',
  'shared.funds': 'Funds',
  'shared.equity': 'Equity',
  'shared.bonds': 'Bonds',
  'shared.selected': 'Selected',
  'shared.assetClass': 'Asset class',
  'shared.years': 'Years',
  'shared.yearsTemplate': '{0} year(s)',
  'shared.monthsTemplate': '{0} month(s)',
  'shared.quartersTemplate': '{0} quarter(s)',
  'shared.calculation': 'Calculation',
  'shared.country': 'Country',
  'shared.export': 'Export',
  'shared.exportAsImage': 'Export as image',
  'shared.unsavedProgress': 'Unsaved progress',
  'shared.youHaveUnsavedChanges_1': 'You have unsaved changes.',
  'shared.youHaveUnsavedChanges_2':
    'Are you sure you want to leave this page without saving?',
  'shared.watchlist': 'Watchlist',
  'shared.preview': 'Preview',
  'shared.send': 'Send',
  'shared.text': 'Text',
  'shared.created': 'Created',
  'shared.group': 'Group',
  'shared.confidenceLevel': 'Confidence level',
  'shared.include': 'Include',
  'shared.exclude': 'Exclude',
  'shared.scoreGroupF1ScoreDescription_1':
    'This F1 score represents the F1 score from the last performed analysis, when assessing the historical predictability of the indicator. If all boxes or two boxes are green, the F1 score represents F1 score from cross-validation. If one box is green, it represents F1 score from training. If all boxes are red, the F1 score is not available.',
  'shared.scoreGroupF1ScoreDescription_2':
    'Higher F1 score in the cross-validation, compared to the F1 score up in the right corner, indicates that the indicator has been good historically, but might not be as predictable for future bear markets.',
  'shared.run': 'Run',
  'shared.confusionMatrix': 'Confusion matrix',
  'shared.noConfusionMatrixInformation': 'No confusion matrix information',
  'shared.confusionMatrixIsEmpty': 'Confusion matrix is empty',
  'shared.noPeriod': 'No period',
  'shared.noF1Score': 'No F1 score',
  'shared.bullPrediction': 'Bull prediction',
  'shared.bearPrediction': 'Bear prediction',
  'shared.copy': 'Copy',
  'shared.rename': 'Rename',
  'shared.sessionName': 'Session Name',
  'shared.start': 'Start',
  'shared.startDate': 'Start date',
  'shared.subscription': 'Subscription',
  'shared.license': 'License',
  'shared.module': 'Module',
  'shared.users': 'Users',
  'shared.totalWeightedScore': 'Total weighted score',
  'shared.totalWeightedScoreDescription_1':
    'Total weighted score = \u2211 of weighted F1 score',
  'shared.totalWeightedScoreDescription_2': 'Where:',
  'shared.totalWeightedScoreDescription_3':
    'weighted F1 score = Weight Training x F1(Training) x Weight CV x F1(CV)',
  'shared.totalWeightedScoreDescription_4':
    'The higher total weighted score, the more it will weight in the composite models. Higher total weighted score also implies that transformation has passed multiple cases of training and validation.',
  'shared.totalWeightedScoreDescription_5':
    'For transformations in confidence level 1-3, total weighted score is only based on the weight and F1 score from training. As they have not passed training and cross-validation, we recommend using them carefully.',
  'shared.weightInModel': 'Weight in model',
  'shared.weightInModelDescription':
    'We use a sigmoid function to normalize the total weighted score to be between 0 and 1 to weight in composite models.',
  'shared.loadingIndicators': 'Loading indicators...',
  'shared.yearToDate': 'YTD',
  'shared.monthToDate': 'MTD',
  'shared.months': '{0}M',
  'shared.yearsFormat': '{0}Y',
  'shared.currency': 'Currency',
  'shared.search': 'Search',
  'shared.portfolio': 'Portfolio',
  'shared.value': 'Value',
  'shared.weight': 'Weight',
  'shared.assets': 'Assets',
  'shared.houseView': 'House view',
  'shared.shortName': 'Short name',
  'shared.fundAllocation': 'Fund allocation',
  'shared.assetsAllocation': 'Assets allocation',
  'shared.sum': 'Sum',
  'shared.historical': 'Historical',
  'shared.amount': 'Amount',
  'shared.reset': 'Reset',
  'shared.purpose': 'Purpose',
  'shared.risk': 'Risk',
  'shared.low': 'Low',
  'shared.medium': 'Medium',
  'shared.high': 'High',
  'shared.max': 'Max',
  'shared.assetClasses': 'Asset classes',
  'shared.riskClasses': 'Risk classes',
  'shared.next': 'Next',
  'shared.back': 'Back',
  'shared.experience': 'Experience',
  'shared.work': 'Work',
  'shared.education': 'Education',
  'shared.neither': 'Neither',
  'shared.debt': 'Debt',
  'shared.liquidity': 'Liquidity',
  'shared.assessment': 'Assessment',
  'shared.english': 'English',
  'shared.norwegian': 'Norwegian',
  'shared.dayOfTheWeek.monday': 'Monday',
  'shared.dayOfTheWeek.tuesday': 'Tuesday',
  'shared.dayOfTheWeek.wednesday': 'Wednesday',
  'shared.dayOfTheWeek.thursday': 'Thursday',
  'shared.dayOfTheWeek.friday': 'Friday',
  'shared.dayOfTheWeek.saturday': 'Saturday',
  'shared.dayOfTheWeek.sunday': 'Sunday',
  'shared.shortDayOfTheWeek.monday': 'Mon',
  'shared.shortDayOfTheWeek.tuesday': 'Tue',
  'shared.shortDayOfTheWeek.wednesday': 'Wed',
  'shared.shortDayOfTheWeek.thursday': 'Thu',
  'shared.shortDayOfTheWeek.friday': 'Fri',
  'shared.shortDayOfTheWeek.saturday': 'Sat',
  'shared.shortDayOfTheWeek.sunday': 'Sun',
  'shared.month.january': 'January',
  'shared.month.february': 'February',
  'shared.month.march': 'March',
  'shared.month.april': 'April',
  'shared.month.may': 'May',
  'shared.month.june': 'June',
  'shared.month.july': 'July',
  'shared.month.august': 'August',
  'shared.month.september': 'September',
  'shared.month.october': 'October',
  'shared.month.november': 'November',
  'shared.month.december': 'December',
  'shared.shortMonth.january': 'Jan',
  'shared.shortMonth.february': 'Feb',
  'shared.shortMonth.march': 'Mar',
  'shared.shortMonth.april': 'Apr',
  'shared.shortMonth.may': 'May',
  'shared.shortMonth.june': 'Jun',
  'shared.shortMonth.july': 'Jul',
  'shared.shortMonth.august': 'Aug',
  'shared.shortMonth.september': 'Sep',
  'shared.shortMonth.october': 'Oct',
  'shared.shortMonth.november': 'Nov',
  'shared.shortMonth.december': 'Dec',
  'shared.date': 'Date',
  'shared.today': 'Today',
  'shared.ok': 'Ok',
  'shared.error.toLongName': 'Name is too long',
  'shared.closeWarningMessage': 'Please click OK to close this message',
  'system.message.text': 'Test Environment - Use test data only',
  'system.message.error404': '404. That`s an error',
  'system.message.requestedUrlWasNotFound':
    'The requested URL {0} was not found on this server.',
  'table.rowsSelected': '{0} row(s) selected',
  'table.rowsPerPage': 'Rows per page',
  'table.rangeOfTotal': '{0} - {1} of {2}',
  'login.login': 'Log in',
  'login.forgot': 'Forgot?',
  'login.emailOrPasswordIsIncorrect': 'The email or password is incorrect',
  'login.pleaseSelectCustomer': 'Please select customer',
  'title.deepAlpha': 'Deep Alpha',
  'title.deepAlphaLogin': 'Deep Alpha Login',
  'title.deepAlphaCustomers': 'Deep Alpha Customers',
  'title.deepAlphaUsersFeedback': 'Deep Alpha Users feedback',
  'title.deepAlphaUserProfile': 'Deep Alpha User profile',
  'title.deepAlphaRoboAdvice': 'Deep Alpha Advisor Solution',
  'title.deepAlphaTools': 'Deep Alpha Tools',
  'title.admin': 'Deep Alpha Admin',
  'navigation.customers': 'Customers',
  'navigation.usersFeedback': 'Users feedback',
  'navigation.advisory': 'Advisory',
  'navigation.tools': 'Tools',
  'navigation.goBack': 'Go back',
  'navigation.homePage': 'Home page',
  'profile.language': 'Language',
  'profile.profileAndPreferences': 'Profile & Preferences',
  'profile.logout': 'Log out',
  'profile.profile': 'Profile',
  'profile.name': 'Name',
  'profile.email': 'Email',
  'profile.country': 'Country',
  'profile.selectCountry': 'Select country',
  'profile.uploadImage': 'Upload image',
  'profile.deleteImage': 'Delete image',
  'profile.dragAndDropFileHere':
    'Drag and drop file here, or click to select file',
  'profile.updateUserPictureSuccessMessage': 'Picture updated successfully',
  'profile.updateUserPictureErrorMessage':
    'Error occurred while updating picture',
  'profile.deleteUserPictureSuccessMessage': 'Picture deleted successfully',
  'profile.deleteUserPictureErrorMessage':
    'Error occurred while deleting picture',
  'profile.nameEmptyValidationError': 'Please enter name',
  'profile.updateUserProfileBasicSuccessMessage':
    'Profile updated successfully',
  'profile.updateUserProfileBasicErrorMessage':
    'Error occurred while updating profile',
  'profile.upgradeSubscriptionNow': 'Upgrade subscription now',
  'profile.youHaveDaysLeftOfYourTrial':
    'You have {0} day(s) left of your trial',
  'profile.yourTrialIsExpired': 'Your trial is expired',
  'profile.yourSubscriptionWillBeAutomaticallyRenewed':
    'Your subscription will be automatically renewed on {0}',
  'profile.timezone': 'Timezone',
  'profile.aboutYou': 'About you',
  'profile.contactInformation': 'Contact information',
  'profile.setTimeZoneAutomatically': 'Set time zone automatically',
  'profile.biography': 'Biography',
  'profile.phone': 'Phone',
  'pageNotFound.pageNotFound': 'Page not found',
  'customers.addCustomer': 'Add customer',
  'customers.editCustomer': 'Edit customer',
  'customers.customersTable.youWantToDeleteTheCustomer':
    'You want to delete the customer.',
  'customers.customersTable.noCustomers': 'No customers',
  'customers.customerManagement.customerName': 'Customer name',
  'customers.customerManagement.customerCode': 'Customer code',
  'customers.customerManagement.advisoryApiKey': 'Advisory api key',
  'customers.customerManagement.customerNameEmptyValidationError':
    'Please enter customer name',
  'customers.customerManagement.createCustomerSuccessMessage':
    'Customer created successfully',
  'customers.customerManagement.createCustomerErrorMessage':
    'Error occurred while creating customer',
  'customers.customerManagement.updateCustomerSuccessMessage':
    'Customer updated successfully',
  'customers.customerManagement.updateCustomerErrorMessage':
    'Error occurred while updating customer',
  'customers.customerManagement.addUsers': 'Add users',
  'customers.customerManagement.addUsersPlaceholder':
    'Enter comma-separated user emails',
  'customers.customerManagement.noUsers': 'No users',
  'customers.customerManagement.noModules': 'No modules',
  'customers.deleteCustomerSuccessMessage': 'Customer deleted successfully',
  'customers.deleteCustomerErrorMessage':
    'Error occurred while deleting customer',
  'customers.customerManagement.licenseRenewalDate': 'License renewal date',
  'customers.customerManagement.selectModule': 'Select module',
  'customers.customerManagement.moduleTypeEmptyValidationError':
    'Please select module',
  'customers.customerManagement.moduleTypeDuplicateValidationError':
    'Please remove module subscription duplicates',
  'customers.customerManagement.selectLicense': 'Select license',
  'customers.customerManagement.licenseTypeEmptyValidationError':
    'Please select license',
  'customers.customerManagement.licenseRenewalDateEmptyValidationError':
    'Please enter license renewal date',
  'customers.customerManagement.licenseRenewalDateInvalidFormatValidationError':
    'Please enter license renewal date in format: {0}',
  'session.yourSubscriptionIsExpired': 'Your subscription is expired',
  'session.upgradeToCreateYourOwnModels_1':
    'Upgrade to Professional to create your own models',
  'session.upgradeToCreateYourOwnModels_2': 'here.',
  'session.updateToCreateUnlimitedIndicators_1':
    'You are using all of your {0} indicators, delete an indicator or upgrade to Professional for unlimited usage',
  'session.updateToCreateUnlimitedIndicators_2': 'here.',
  'session.unisingOfTotalIndicators_1':
    'Using {0} of {1} indicators. Upgrade to Professional for unlimited usage',
  'session.unisingOfTotalIndicators_2': 'here.',
  'confirmation.yes': 'Yes',
  'confirmation.no': 'No',
  'admin.userManagement': 'User management',
  'admin.trashCan': 'Trash can',
  'admin.appAdmin': 'App admin',
  'admin.settings': 'Settings',
  'admin.manageCustomer.readCustomerErrorMessage':
    'Error occured while loading customer data',
  'admin.manageCustomer.updateCustomerErrorMessage':
    'Error occured while updating customer data',
  'admin.manageCustomer.customerName': 'Customer name',
  'admin.manageCustomer.subscription': 'Subscription',
  'admin.manageCustomer.noModules': 'No modules',
  'admin.manageCustomer.module': 'Module',
  'admin.manageCustomer.license': 'License',
  'admin.manageCustomer.licenseRenewalDate': 'License renewal date',
  'admin.manageCustomer.addUsers': 'Add users',
  'admin.manageCustomer.addUser.placeholder': 'e-mail',
  'admin.manageCustomer.addUser.emptyValidationError': 'Please enter e-mail',
  'admin.manageCustomer.invite': 'Invite',
  'admin.manageCustomer.manageUsers': 'Manage users',
  'admin.manageCustomer.noUsers': 'No users',
  'admin.manageCustomer.eMail': 'E-mail',
  'admin.manageCustomer.admin': 'Admin',
  'admin.manageCustomer.auth0': 'Auth0',
  'admin.trashCan.navItems.clients': 'Clients',
  'admin.trashCan.navItems.adviceSessions': 'Advice sessions',
  'admin.trashCan.actionButtons.restore': 'Restore marked',
  'admin.trashCan.actionButtons.delete': 'Delete marked',
  'admin.trashCan.adviceSessions.readAdviceSessionsErrorMessage':
    'Error occured while loading advice sessions',
  'admin.trashCan.adviceSessions.deleteAdviceSessionsErrorMessage':
    'Error occured while deleting advice sessions',
  'admin.trashCan.adviceSessions.deleteAdviceSessionsSuccessMessage':
    'All sessions deleted successfully',
  'admin.trashCan.adviceSessions.deleteAdviceSessionsHalfSuccessfulMessage':
    'Deleted {0} sessions, but {1} failed',
  'admin.trashCan.adviceSessions.deleteAdviceSessionsFailedMessage':
    'Deletion of sessions failed',
  'admin.trashCan.adviceSessions.restoreAdviceSessionsErrorMessage':
    'Error occured while restoring advice sessions',
  'admin.trashCan.adviceSessions.restoreAdviceSessionsSuccessMessage':
    'Sessions restored successfully.',
  'admin.appAdmin.advisorSolution.text.redTranslationsErrorMessage':
    'Error occured while loading translations',
  'admin.appAdmin.advisorSolution.text.saveTranslationsSuccessMessage':
    'Translations saved successfully',
  'admin.appAdmin.advisorSolution.text.saveTranslationsErrorMessage':
    'Error occured while saving translations',
  'admin.appAdmin.advisorSolution.wrongPlaceholders': 'Placeholders must match',
  'admin.appAdmin.navItems.advisorSolution': 'Advisor Solution',
  'admin.appAdmin.advisorSolution.navigation.navItems.text': 'Text',
  'admin.appAdmin.advisorSolution.navigation.navItems.visuals': 'Visuals',
  'admin.appAdmin.advisorSolution.navigation.navItems.preview': 'Preview',
  'admin.appAdmin.advisorSolution.navigation.navItems.proposal': 'Proposal',
  'admin.appAdmin.advisorSolution.navigation.navItems.sustainability':
    'Sustainability',
  'admin.appAdmin.advisorSolution.text.searchInput.placeholder': 'Search text',
  'admin.appAdmin.advisorSolution.text.table.header.description': 'Description',
  'admin.appAdmin.advisorSolution.text.table.header.default':
    'Default (read-only)',
  'admin.appAdmin.advisorSolution.text.table.header.yourConfig':
    'Your configuration',
  'admin.appAdmin.advisorSolution.text.table.noTranslations': 'No translations',
  'admin.appAdmin.advisorSolution.text.table.noMatchingTranslations':
    'No matching translations',
  'admin.appAdmin.advisorSolution.text.table.yourConfigPlaceholder':
    'type here',
  'admin.appAdmin.advisorSolution.text.save': 'Save',
  'admin.appAdmin.advisorSolution.text.switchLabel': 'Admin mode',
  'admin.appAdmin.advisorSolution.text.confirmationMessage1':
    'You have unsaved changes.',
  'admin.appAdmin.advisorSolution.text.confirmationMessage2':
    'Are you sure you want to continue?',
  'admin.appAdmin.advisorSolution.text.emptyDefaultFieldError':
    'Default value cannot be empty',
  'admin.appAdmin.advisorSolution.visuals.readSettingsErrorMessage':
    'Error occured while loading settings',
  'admin.appAdmin.advisorSolution.visuals.updateSettingsErrorMessage':
    'Error occured while updating settings',
  'admin.appAdmin.advisorSolution.visuals.updateSettingsSuccessMessage':
    'Settings updated successfully',
  'admin.appAdmin.advisorSolution.visuals.logo': 'Logo',
  'admin.appAdmin.advisorSolution.visuals.uploadLogo': '+ Upload logo',
  'admin.appAdmin.advisorSolution.visuals.uploadLogoDescription':
    'JPG, PNG, SVG, size less than 500 KB',
  'admin.appAdmin.advisorSolution.visuals.emptyLogoValidationErrorMessage':
    'Please select logo',
  'admin.appAdmin.advisorSolution.visuals.logoFileTypeValidationErrorMessage':
    'Please select logo file with type JPG, PNG, or SVG',
  'admin.appAdmin.advisorSolution.visuals.logoFileSizeValidationErrorMessage':
    'Please select logo file with size less than 500 KB',
  'admin.appAdmin.advisorSolution.visuals.generalColours': 'General colours',
  'admin.appAdmin.advisorSolution.visuals.generalColoursDescription':
    'Adjust these main colours for your interface. See the preview below.',
  'admin.appAdmin.advisorSolution.visuals.secondaryShades': 'Secondary shades',
  'admin.appAdmin.advisorSolution.visuals.secondaryShadesDescription':
    'The shades automatically match your colour scheme. Not necessary to set them manually.',
  'admin.appAdmin.advisorSolution.visuals.infographicsColours':
    'Infographics colours',
  'admin.appAdmin.advisorSolution.visuals.infographicsColoursDescription':
    'Used for pie charts, donut charts and graphs.',
  'admin.appAdmin.advisorSolution.visuals.corners': 'Corners',
  'admin.appAdmin.advisorSolution.visuals.cornersDescription':
    'Please select corner type for input fields, cards and pop-up windows.',
  'admin.appAdmin.advisorSolution.visuals.accent': 'Accent',
  'admin.appAdmin.advisorSolution.visuals.primary': 'Primary',
  'admin.appAdmin.advisorSolution.visuals.pageBackground': 'Page background',
  'admin.appAdmin.advisorSolution.visuals.defaultInputStroke':
    'Default input stroke',
  'admin.appAdmin.advisorSolution.visuals.success': 'Success',
  'admin.appAdmin.advisorSolution.visuals.error': 'Error - negative',
  'admin.appAdmin.advisorSolution.visuals.warning': 'Warning',
  'admin.appAdmin.advisorSolution.visuals.hoverAccent': 'Hover accent',
  'admin.appAdmin.advisorSolution.visuals.focusAccent': 'Focus accent',
  'admin.appAdmin.advisorSolution.visuals.defaultButtonText':
    'Default button text',
  'admin.appAdmin.advisorSolution.visuals.inputFillFocus': 'Input fill focus',
  'admin.appAdmin.advisorSolution.visuals.disabledBackground':
    'Disabled background',
  'admin.appAdmin.advisorSolution.visuals.secondary': 'Secondary',
  'admin.appAdmin.advisorSolution.visuals.disabledText': 'Disabled text',
  'admin.appAdmin.advisorSolution.visuals.tertiaryText': 'Tertiary text',
  'admin.appAdmin.advisorSolution.visuals.boxBackground_1': 'Box background 1',
  'admin.appAdmin.advisorSolution.visuals.boxBackground_2': 'Box background 2',
  'admin.appAdmin.advisorSolution.visuals.defaultInputFill':
    'Default input fill',
  'admin.appAdmin.advisorSolution.visuals.itemBackground_1':
    'Item background 1',
  'admin.appAdmin.advisorSolution.visuals.itemBackground_2':
    'Item background 2',
  'admin.appAdmin.advisorSolution.visuals.hoverInputStroke':
    'Hover input stroke',
  'admin.appAdmin.advisorSolution.visuals.chartColor': 'Chart color',
  'admin.appAdmin.advisorSolution.visuals.emptyChart': 'Empty chart',
  'admin.appAdmin.advisorSolution.visuals.round': 'Round',
  'admin.appAdmin.advisorSolution.visuals.sharp': 'Sharp',
  'admin.appAdmin.advisorSolution.visuals.emptyColorValidationErrorMessage':
    'Please enter color',
  'admin.appAdmin.advisorSolution.visuals.invalidColorFormatValidationErrorMessage':
    'Please enter color in format: RRGGBB',
  'admin.appAdmin.advisorSolution.visuals.emptyBorderRadiusTypeValidationErrorMessage':
    'Please select corners type',
  'admin.appAdmin.advisorSolution.visuals.feedbackColors': 'Feedback Colors',
  'admin.save': 'Save',
  'admin.appAdmin.advisorSolution.preview.readCustomerConfigErrorMessage':
    'Error occured while loading customer configuration',
  'admin.appAdmin.advisorSolution.proposal.sectionHeader.goalTable':
    'Goal Table',
  'admin.appAdmin.advisorSolution.proposal.sectionHeader.analyticsComponents':
    'Analytics Components',
  'admin.appAdmin.advisorSolution.proposal.equityPortion': 'Equity portion',
  'admin.appAdmin.advisorSolution.proposal.expectedAnnualReturn':
    'Expected annual return',
  'admin.appAdmin.advisorSolution.proposal.expectedValueInYears':
    'Expeced value in years',
  'admin.appAdmin.advisorSolution.proposal.expectedVolatility':
    'Expected volatility',
  'admin.appAdmin.advisorSolution.proposal.firstDeposit': 'First deposit',
  'admin.appAdmin.advisorSolution.proposal.goalName': 'Goal name',
  'admin.appAdmin.advisorSolution.proposal.monthlyDeposit': 'Monthly deposit',
  'admin.appAdmin.advisorSolution.proposal.riskScore': 'Risk score',
  'admin.appAdmin.advisorSolution.proposal.years': 'Time horizon in years',
  'admin.appAdmin.advisorSolution.proposal.isPortfolioHidden': 'Portfolio',
  'admin.appAdmin.advisorSolution.proposal.isExpectedValueHidden':
    'Expected value',
  'admin.appAdmin.advisorSolution.proposal.isExpectedPathHidden':
    'Expected path',
  'admin.appAdmin.advisorSolution.proposal.isCostHidden': 'Cost',
  'admin.appAdmin.advisorSolution.proposal.isEfficientFrontierHidden':
    'Efficient frontier',
  'admin.appAdmin.advisorSolution.proposal.isSustainabilityHidden':
    'Sustainability',
  'admin.appAdmin.advisorSolution.proposal.isOrderSummaryHidden':
    'Order summary',
  'roboAdvice.integrationPlatform.nothingToSyncMessage':
    'Nothing to synchronize',
  'roboAdvice.integrationPlatform.syncErrorMessage':
    'Error occured while synchronizing external data',
  'roboAdvice.integrationPlatform.couldNotRedirectEndpointNotProvidedErrorMessage':
    'Could not redirect, because qip sync endpoint was not provided',
  'roboAdvice.integrationPlatform.couldNotRedirectInvestorDataNotProvidedErrorMessage':
    'Could not redirect, because not enough data was provided',
  'roboAdvice.integrationPlatform.CRMRedirectFailedDefaultingToHomeWarning':
    'CRM redirect failed, defaulting to Home',
  'roboAdvice.shared.backToTheSession': 'Back to the session',
  'roboAdvice.clientsList.readClientsErrorMessage':
    'Error occured while loading clients data',
  'roboAdvice.clientsList.deleteClientErrorMessage':
    'Error occured while deleting client',
  'roboAdvice.clientsList.deleteClientSuccessMessage':
    'Client deleted successfully',
  'roboAdvice.clientsList.transferClientErrorMessage':
    'Error occured while transfering client',
  'roboAdvice.clientsList.transferClientSuccessMessage':
    'Client transfered successfully',
  'roboAdvice.breadcrumbs.advisorSolution': 'Advisor Solution',
  'roboAdvice.breadcrumbs.clients': 'Clients',
  'roboAdvice.breadcrumbs.addClient': 'Add client',
  'roboAdvice.clientsList.allClients': 'All clients',
  'roboAdvice.clientsList.myClients': 'My clients',
  'roboAdvice.clientsList.addClient': 'Add client',
  'roboAdvice.clientsList.searchClient': 'Search client',
  'roboAdvice.clientsList.clientsTable.type': 'Type',
  'roboAdvice.clientsList.clientsTable.name': 'Name',
  'roboAdvice.clientsList.clientsTable.email': 'Email',
  'roboAdvice.clientsList.clientsTable.advisor': 'Advisor',
  'roboAdvice.clientsList.clientsTable.noClients': 'No clients',
  'roboAdvice.clientsList.clientsTable.deleteClientConfirmationMessage':
    'Are you sure you want to delete {0}?',
  'roboAdvice.clientsList.clientsTable.takeOverClientConfirmationMessage':
    'Are you sure you want to take over {0}?',
  'roboAdvice.client.clientInformation': 'Client information',
  'roboAdvice.client.adviceSessions': 'Advice sessions',
  'roboAdvice.client.readClientInformationErrorMessage':
    'Error occured while loading client information',
  'roboAdvice.client.readAdviceSessionsErrorMessage':
    'Error occured while loading advice sessions',
  'roboAdvice.client.createClientErrorMessage':
    'Error occured while creating client',
  'roboAdvice.client.createClientSuccessMessage': 'Client created successfully',
  'roboAdvice.client.updateClientErrorMessage':
    'Error occured while updating client',
  'roboAdvice.client.updateClientSuccessMessage': 'Client updated successfully',
  'roboAdvice.client.save': 'Save',
  'roboAdvice.client.addNewAdvice': 'Add new advice',
  'roboAdvice.client.adviceSessionsTable.name': 'Name',
  'roboAdvice.client.adviceSessionsTable.created': 'Created',
  'roboAdvice.client.adviceSessionsTable.lastActivityDate':
    'Last Activity Date',
  'roboAdvice.client.adviceSessionsTable.channel': 'Channel',
  'roboAdvice.client.adviceSessionsTable.status': 'Status',
  'roboAdvice.client.adviceSessionsTable.noAdviceSessions':
    'No advice sessions',
  'roboAdvice.client.adviceSessionsTable.downloadReport': 'Download report',
  'roboAdvice.client.adviceSessionsTable.downloadReportWithAttachments':
    'Download report with attachments',
  'roboAdvice.client.adviceSessionsTable.customDownload': 'Custom download',
  'roboAdvice.client.adviceSessionsTable.pdfStatus': 'PDF Status',
  'roboAdvice.client.adviceSessionsTable.downloadMergedPDF':
    'Download customized PDF',
  'roboAdvice.client.adviceSessionsTable.errorWhileFetchingDocuments':
    'Error occured while fetching documents',
  'roboAdvice.client.adviceSessionsTable.errorWhileDownloadingReportDocuments':
    'Error occured while downloading documents',
  'roboAdvice.client.adviceSessionsTable.deleteAdviceSessionConfirmationMessage':
    'Are you sure you want to delete {0}?',
  'roboAdvice.client.adviceSessionsTable.defaultAdviceSessionName':
    'advice session',
  'roboAdvice.client.adviceSessionStatuses.new': 'New',
  'roboAdvice.client.adviceSessionStatuses.creating': 'Creating...',
  'roboAdvice.client.adviceSessionStatuses.ongoing': 'Ongoing',
  'roboAdvice.client.adviceSessionStatuses.completed': 'Completed',
  'roboAdvice.client.copyAdviceSessionInProgressMessage':
    '{0} advice session copying in progress',
  'roboAdvice.client.followUpAdviceSessionInProgressMessage':
    'Creating follow-up to {0} advice session in progress',
  'roboAdvice.client.copyAdviceSessionErrorMessage':
    'Error occured while creating {0} advice session copy',
  'roboAdvice.client.followUpAdviceSessionErrorMessage':
    'Error occured while creating {0} follow-up advice session',
  'roboAdvice.client.copyAdviceSessionSuccessMessage':
    '{0} advice session copy created successfully',
  'roboAdvice.client.followUpAdviceSessionSuccessMessage':
    '{0} follow-up advice session created successfully',
  'roboAdvice.client.deleteAdviceSessionErrorMessage':
    'Error occured while deleting advice session',
  'roboAdvice.client.deleteAdviceSessionSuccessMessage':
    'Advice session deleted successfully',
  'roboAdvice.client.createAdviceSessionErrorMessage':
    'Error occured while creating advice session',
  'roboAdvice.client.updateAdviceSessionSuccessMessage':
    'Advice session updated successfully',
  'roboAdvice.client.updateAdviceSessionErrorMessage':
    'Error occured while updating advice session',
  'roboAdvice.client.adviceSessionChannels.advisoryChannel': 'Advisory channel',
  'roboAdvice.client.adviceSessionChannels.selfService': 'Self service',
  'roboAdvice.adviceSession.client': 'Client',
  'roboAdvice.adviceSession.changeClient': 'Change client',
  'roboAdvice.adviceSession.adviceSession': 'Advice session',
  'roboAdvice.adviceSession.readAdviceSessionErrorMessage':
    'Error occured while loading advice session',
  'roboAdvice.adviceSession.readRiskScoreSettingsErrorMessage':
    'Error occured while loading risk score data',
  'roboAdvice.client.typeCanNotBeChanged':
    'Existing client type can not be changed',
  'roboAdvice.proposal.cost': 'Cost',
  'roboAdvice.proposal.costElements': 'Cost elements',
  'roboAdvice.proposal.cost.summary': 'Summary',
  'roboAdvice.proposal.cost.year1': 'Year 1',
  'roboAdvice.proposal.cost.yearX': 'Year',
  'roboAdvice.proposal.cost.fundDetailsAmount': 'Fund details, amount',
  'roboAdvice.proposal.cost.fundDetails%': 'Fund details, %',
  'roboAdvice.proposal.cost.fund': 'Fund',
  'roboAdvice.proposal.cost.managementFee': 'Management Fee',
  'roboAdvice.proposal.cost.oneTimeCost': 'One time cost',
  'roboAdvice.proposal.cost.transactionCost': 'Transaction cost',
  'roboAdvice.proposal.cost.performanceFee': 'Performance fee',
  'roboAdvice.proposal.cost.successFee': 'Success fee',
  'roboAdvice.proposal.cost.platformFee': 'Platform fee',
  'roboAdvice.proposal.cost.totalExpenseRatio': 'Total expense ratio',
  'roboAdvice.proposal.cost.asterisk':
    '* Measured in % based on expected value before costs',
  'roboAdvice.proposal.cost.annualOngoingFees': 'Annual ongoing fees',
  'roboAdvice.proposal.cost.oneTimeFees': 'One time fees',
  'roboAdvice.proposal.cost.aggregatedCostAfterYears':
    'Aggregated cost effect after {0} years',
  'roboAdvice.proposal.cost.aggregatedCostAfter1Year':
    'Aggregated cost effect after 1 year',
  'roboAdvice.proposal.cost.aggregatedOngoingFees': 'Aggregated ongoing fees',
  'roboAdvice.proposal.cost.aggregatedOneTimeFee': 'Aggregated one time fee',
  'roboAdvice.proposal.cost.aggregatedCommissionAfterYears':
    'Aggregated return commission paid to advisor after {0} years',
  'roboAdvice.proposal.cost.aggregatedCommissionAfter1Year':
    'Aggregated return commission paid to advisor after 1 year',
  'roboAdvice.proposal.cost.emptyAdvisorNoteMessage': 'Enter advisor note',
  'roboAdvice.proposal.cost.overrideCostValueErrorMessage':
    'Cost value must be between {0} and {1}',
  'roboAdvice.proposal.cost.reasoningForOversteeredCost':
    'Reasoning for oversteered cost',
  'shared.emptyInputErrorMessage': 'Please enter {0}',
  'shared.emptySelectErrorMessage': 'Please select {0}',
  'roboAdvice.detalizedNumberInput.titleEmptyValidationError':
    'Please enter title',
  'roboAdvice.detalizedNumberInput.valueEmptyValidationError':
    'Please enter value',
  'roboAdvice.detalizedNumberInput.total': 'Total',
  'roboAdvice.detalizedNumberInput.defaultValueEmptyValidationError':
    'Please enter value',
  'roboAdvice.navigation.next': 'Next',
  'roboAdvice.navigation.back': 'Back',
  'roboAdvice.navigation.close': 'Close',
  'roboAdvice.advisorNote': 'Advisor note',
  'roboAdvice.advisorNoteRequired': 'Required Advisor note',
  'roboAdvice.tabs.purposeAndRisk': 'Purpose & risk',
  'roboAdvice.tabs.knowledgeAndExperience': 'Knowledge & experience',
  'roboAdvice.tabs.financialSituation': 'Financial situation',
  'roboAdvice.tabs.sustainability': 'Sustainability',
  'roboAdvice.tabs.advisory': 'Advisory',
  'roboAdvice.tabs.proposal': 'Proposal',
  'roboAdvice.tabs.order': 'Order',
  'roboAdvice.tabs.clientInformation': 'Client information',
  'roboAdvice.tabs.adviceInformation': 'Advice information',
  'roboAdvice.tabs.orderInformation': 'Order information',
  'roboAdvice.tabs.instruments': 'Instruments',
  'roboAdvice.notifications.readRiskScoreFailed':
    'Error occurred while loading the score',
  'roboAdvice.notifications.readRiskScoreConfigFailed':
    'Error occurred while loading the score configuration',
  'roboAdvice.riskScore.initialRiskScore': 'Initial risk score',
  'roboAdvice.riskScore.adjustment': 'Adjustment',
  'roboAdvice.riskScore.finalRiskScore': 'Final risk score',
  'roboAdvice.riskScore.noPurposeAndRiskData': 'No initial risk score data',
  'roboAdvice.riskScore.noKnowledgeAndExperienceData': 'No adjustment data',
  'roboAdvice.riskScore.noRiskScoreData': 'No final risk score data',
  'roboAdvice.riskScore.horizonRisk': 'Horizon / Risk',
  'roboAdvice.riskScore.contributionToRiskScoreDescription':
    'Contribution to risk score is calculated by the average of the two scores. If only one score is available, that is used.',
  'roboAdvice.riskScore.expectationOfRisk.lowRisk': 'Low',
  'roboAdvice.riskScore.expectationOfRisk.mediumRisk': 'Medium',
  'roboAdvice.riskScore.expectationOfRisk.highRisk': 'High',
  'roboAdvice.riskScore.expectationOfRisk.maximumRisk': 'Max',
  'roboAdvice.riskScore.reactionIfMarketFails': 'Reaction if market fails',
  'roboAdvice.riskScore.abilityToBearLosses': 'Ability to bear losses',
  'roboAdvice.riskScore.experience': 'Experience',
  'roboAdvice.riskScore.educationWork.iHaveWorkExperience': 'Work',
  'roboAdvice.riskScore.educationWork.iHaveEducation': 'Education',
  'roboAdvice.riskScore.educationWork.iHaveNoExperience': 'Neither',
  'roboAdvice.riskScore.experience.lessThan5':
    'I have little or no experience of trading shares or funds',
  'roboAdvice.riskScore.experience.between5And10':
    'I have traded stocks and funds more than 5 times over the last 5 years',
  'roboAdvice.riskScore.experience.moreThan10':
    'I have traded stocks and funds more than 10 times over the last 5 years',
  'roboAdvice.riskScore.assetClasses': 'Asset classes',
  'roboAdvice.riskScore.riskClasses': 'Risk classes',
  'roboAdvice.riskScore.productConfiguration': 'Product configuration',
  'roboAdvice.riskScore.compareWithExistingPortfolio':
    'Compare with existing portfolio',
  'roboAdvice.riskScore.deselectOnePortfolioToCompareWithExistingPortfolio':
    'Deselect one portfolio to compare with existing portfolio.',
  'roboAdvice.riskScore.warningTooltipNotAllAssetsTagged':
    'To include the Existing Portfolio you have to ensure all assets are tagged to an asset class',
  'roboAdvice.purposeAndRisk.purpose': 'Purpose',
  'roboAdvice.purposeAndRisk.risk': 'Risk',
  'roboAdvice.purposeAndRisk.whatIsYourExpectationOfRisk':
    'What is your expectation of risk?',
  'roboAdvice.purposeAndRisk.whereShouldYouPutYourMoney':
    'Where should you put your money?',
  'roboAdvice.purposeAndRisk.whatAreYouSavingFor': 'What are you saving for?',
  'roboAdvice.purposeAndRisk.timeHorizon': 'Time horizon',
  'roboAdvice.purposeAndRisk.timeHorizonSuffix': 'years',
  'roboAdvice.purposeAndRisk.expectationOfRisk.lowRisk': 'Low risk',
  'roboAdvice.purposeAndRisk.expectationOfRisk.lowRiskDescription':
    'Accept lower returns, want minor fluctuations',
  'roboAdvice.purposeAndRisk.expectationOfRisk.mediumRisk': 'Medium risk',
  'roboAdvice.purposeAndRisk.expectationOfRisk.mediumRiskDescription':
    'Hope for moderate returns, accept fluctuations',
  'roboAdvice.purposeAndRisk.expectationOfRisk.highRisk': 'High risk',
  'roboAdvice.purposeAndRisk.expectationOfRisk.highRiskDescription':
    'Hope for high returns, accept large fluctuations',
  'roboAdvice.purposeAndRisk.expectationOfRisk.maximumRisk': 'Maximum risk',
  'roboAdvice.purposeAndRisk.expectationOfRisk.maximumRiskDescription':
    'Hope for greatest possible return, accept larger fluctuations',
  'roboAdvice.purposeAndRisk.expectationOfRisk.ultraRisk': 'Ultra risk',
  'roboAdvice.purposeAndRisk.expectationOfRisk.ultraRiskDescription':
    'No possible return At All',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioA': 'Scenario A',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioADescription':
    'XXX in return',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioB': 'Scenario B',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioBDescription':
    'XXX in return',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioC': 'Scenario C',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioCDescription':
    'XXX in return',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioD': 'Scenario D',
  'roboAdvice.purposeAndRisk.riskStrategy.scenarioDDescription':
    'XXX in return',
  'roboAdvice.purposeAndRisk.riskStrategy.maximumGain': 'Maximum gain',
  'roboAdvice.purposeAndRisk.riskStrategy.maximumLoss': 'Maximum loss',
  'roboAdvice.purposeAndRisk.savingPurposeEmptyValidationError':
    'Please enter saving goal',
  'roboAdvice.purposeAndRisk.timeHorizonEmptyValidationError':
    'Please enter time horizon',
  'roboAdvice.purposeAndRisk.timeHorizonNegativeValidationError':
    'Saving goal should be greater than 0',
  'roboAdvice.purposeAndRisk.riskQuestionAnswersEmptyValidationError':
    'Please answer at least one risk question',
  'roboAdvice.purposeAndRisk.expectationOfRiskEmptyValidationError':
    'Please answer the question',
  'roboAdvice.purposeAndRisk.riskStrategyEmptyValidationError':
    'Please answer the question',
  'roboAdvice.knowledgeAndExperience.quiz.popupButton': 'I understand',
  'roboAdvice.knowledgeAndExperience.quiz.trainingPerformed':
    'Training performed with advisor',
  'roboAdvice.knowledgeAndExperience.quiz.learnMore': 'Learn more',
  'roboAdvice.knowledgeAndExperience.quiz.modalHeader': 'Discretionary Mandate',
  'roboAdvice.knowledgeAndExperience.quizError':
    'Unfortunately we can not assist, as you have stated that you do not understand the relationship between risk and return or the services we offer',
  'roboAdvice.savingsPlan.monthlyDeposit': 'Monthly deposit',
  'roboAdvice.savingsPlan.monthlyDepositEmptyValidationError':
    'Please enter monthly deposit',
  'roboAdvice.savingsPlan.firstDeposit': 'First deposit',
  'roboAdvice.savingsPlan.firstDepositEmptyValidationError':
    'Please enter first deposit',
  'roboAdvice.savingsPlan.internalHolding':
    'Value of internal holding to advisory',
  'roboAdvice.savingsPlan.addOn': 'Add on',
  'roboAdvice.savingsPlan.withdrawal': 'Withdrawal',
  'roboAdvice.advisory.themes': 'Themes',
  'roboAdvice.advisory.savingsPlan': 'Savings plan',
  'roboAdvice.advisory.portfolio': 'Portfolio',
  'roboAdvice.advisory.portfolio.create': 'Create',
  'roboAdvice.advisory.portfolio.edit': 'Edit',
  'roboAdvice.advisory.portfolio.openEditor': 'Open editor',
  'roboAdvice.advisory.portfolio.portfolioEmptyValidationError':
    'Please select portfolio',
  'roboAdvice.advisory.portfolio.customPortfolioEmptyValidationError':
    'Please build portfolio',
  'roboAdvice.advisory.portfolio.modelPortfolio': 'Model portfolio',
  'roboAdvice.advisory.portfolio.customPortfolio': 'Custom portfolio',
  'roboAdvice.advisory.portfolio.existingPortfolio': 'Existing portfolio',
  'roboAdvice.advisory.portfolio.riskClassPortfolio': 'Risk class Portfolio',
  'roboAdvice.advisory.portfolio.otherAssetClasses': 'Other Asset Classes',
  'roboAdvice.advisory.buildPortfolio.useExistingPortfolio':
    'Use existing portfolio',
  'roboAdvice.advisory.buildPortfolio.selectPortfolio': 'Select portfolio',
  'roboAdvice.advisory.buildPortfolio.searchInstrument': 'Search instrument',
  'roboAdvice.advisory.buildPortfolio.noInstrumentsFound':
    'No instruments found',
  'roboAdvice.advisory.buildPortfolio.loadingInstruments':
    'Loading instruments...',
  'roboAdvice.advisory.buildPortfolio.save': 'Save',
  'roboAdvice.advisory.buildPortfolio.cancel': 'Cancel',
  'roboAdvice.advisory.buildPortfolio.sum': 'Sum',
  'roboAdvice.advisory.buildPortfolio.portfolioEmptyValidationError':
    'Please select portfolio',
  'roboAdvice.advisory.buildPortfolio.instrumentsEmptyValidationError':
    'Please select at least one instrument',
  'roboAdvice.advisory.buildPortfolio.instrumentWeightEmptyValidationError':
    'Please enter weight',
  'roboAdvice.advisory.buildPortfolio.weightsSumValidationError':
    'Sum actual allocation must be 100%',
  'roboAdvice.advisory.buildPortfolio.deviateFromTargetAllocationError':
    'Actual allocation deviates from the target allocation',
  'roboAdvice.advisory.buildPortfolio.emptyPortfolioTitleError':
    'Enter the portfolio name',
  'roboAdvice.advisory.buildPortfolio.assetClass': 'Asset class',
  'roboAdvice.advisory.buildPortfolio.targetAllocation': 'Target allocation',
  'roboAdvice.advisory.buildPortfolio.actualAllocation': 'Actual allocation',
  'roboAdvice.advisory.buildPortfolio.selectInstrument': 'Select instrument',
  'roboAdvice.advisory.buildPortfolio.instrument': 'Instrument',
  'roboAdvice.advisory.buildPortfolio.weight': 'Weight',
  'roboAdvice.advisory.buildPortfolio.noAssetClasses': 'No asset classes',
  'roboAdvice.advisory.buildPortfolio.showAllAssets': 'Show all asset classes',
  'roboAdvice.advisory.portfolio.portfolioTitle': 'Risk {0}',
  'roboAdvice.advisory.advisorNotes.advisorNotesEmptyValidationError':
    'Please enter advisor notes',
  'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError':
    'Please enter at least {0} characters',
  'roboAdvice.advisory.customPortfolio.tabs.assetClassAllocation':
    'Asset Class Allocation',
  'roboAdvice.advisory.customPortfolio.tabs.fundAllocation': 'Fund Allocation',
  'roboAdvice.advisory.customPortfolio.tabs.historicalReturn':
    'Historical Return',
  'roboAdvice.advisory.customPortfolio.tabs.sustainability': 'Sustainability',
  'roboAdvice.advisory.customPortfolio.addAssetClass': '+ Add asset class',
  'roboAdvice.advisory.customPortfolio.riskTemplate': '+ Risk Template',
  'roboAdvice.advisory.customPortfolio.emptyAssetTablePlaceholder':
    'Create custom portfolio by start adding asset classes',
  'roboAdvice.advisory.customPortfolio.addAssetClassModalHeader':
    'Add Asset Class',
  'roboAdvice.advisory.customPortfolio.riskTemplatesModalHeader':
    'Risk Templates',
  'roboAdvice.advisory.customPortfolio.allAssetClassesAreSelected':
    'All asset classes are selected',
  'roboAdvice.advisory.customPortfolio.selectATemplate': 'Select a template',
  'roboAdvice.advisory.customPortfolio.overrideAllocationConfirmationHeader':
    'Override Allocation with Risk Template',
  'roboAdvice.advisory.customPortfolio.overrideAllocationConfirmationBody':
    'Are you sure you want to override current asset allocation with a new risk level asset allocation? Note that asset classes that are not included in the new allocation will be removed.',
  'roboAdvice.advisory.customPortfolio.deleteAssetClassConfirmationMessage':
    'Are you sure you want to delete this asset class?',
  'roboAdvice.advisory.customPortfolio.deleteAssetClassConfirmationTitle':
    'Delete Asset Class',
  'roboAdvice.advisory.customPortfolio.riskTemplateErrorMessage':
    'Error occured while getting new risk template',
  'roboAdvice.advisory.customPortfolio.reselect': 'Reselect',
  'roboAdvice.advisory.customPortfolio.reselectConfirmationHeader':
    'Automatically Select Funds',
  'roboAdvice.advisory.customPortfolio.reselectConfirmationBody':
    'Are you sure you want to override current fund selection? Note that asset classes that are not included in the new allocation will be removed.',
  'roboAdvice.advisory.customPortfolio.fundSelector': 'Fund Selector',
  'roboAdvice.advisory.customPortfolio.allocation': 'Allocation',
  'roboAdvice.advisory.customPortfolio.fundManagementFee':
    'Fund Management Fee',
  'roboAdvice.advisory.customPortfolio.emptyFundAllocationTablePlaceholder':
    'This portfolio requires asset class allocation before instruments can be selected.',
  'roboAdvice.advisory.customPortfolio.reselectTooltip':
    'Reselect saves your time if you have changed asset class allocation and will quickly generate new fund selection. Reselect automatically populates funds with the equal weight for each asset class.',
  'roboAdvice.advisory.customPortfolio.readFundManagementFeesError':
    'Error occured while reading fund management fees',
  'roboAdvice.advisory.customPortfolio.readAssetClassInstrumentsError':
    'Error occured while reading asset class instruments',
  'roboAdvice.advisory.customPortfolio.initializeCustomPortfolioError':
    'Error occurred while initializing custom portfolio',
  'roboAdvice.advisory.customPortfolio.fetchingCustomPortfolioAssetsError':
    'Error occurred while fetching custom portfolio assets',
  'roboAdvice.advisory.customPortfolio.overview': 'Overview',
  'roboAdvice.advisory.customPortfolio.sustainability': 'Sustainability',
  'roboAdvice.advisory.customPortfolio.preferenceCriteria':
    'Preference Criteria',
  'roboAdvice.advisory.customPortfolio.fundSelection': 'Fund Selection',
  'roboAdvice.advisory.customPortfolio.investorsPreference':
    "Investor's Preference",
  'roboAdvice.advisory.customPortfolio.readUniverseSustainabilityError':
    'Error occurred while reading universe sustainability data',
  'roboAdvice.advisory.customPortfolio.readUniverseCustomAttributesError':
    'Error occurred while reading universe custom attributes data',
  'roboAdvice.advisory.customPortfolio.suitability': 'Suitability',
  'roboAdvice.advisory.customPortfolio.suitable': 'Suitable',
  'roboAdvice.advisory.customPortfolio.notSuitable': 'Not suitable',
  'roboAdvice.advisory.customPortfolio.suitableInPortfolio':
    'Suitable in a portfolio',
  'roboAdvice.advisory.modelPortfolio.riskModel': 'Risk Model',
  'roboAdvice.proposal.readKeyStatisticsErrorMessage':
    'Error occurred while loading key statistics',
  'roboAdvice.proposal.readDataErrorMessage':
    'Error occurred while loading data',
  'roboAdvice.proposal.riskScore': 'Risk score',
  'roboAdvice.proposal.keyStatistics': 'Key statistics',
  'roboAdvice.proposal.generateProposal': 'Generate proposal',
  'roboAdvice.proposal.expectedAnnualReturn': 'Expected annual return',
  'roboAdvice.proposal.expectedVolatility': 'Expected volatility',
  'roboAdvice.proposal.equityPortion': 'Equity portion',
  'roboAdvice.proposal.expectedValueInXYears': 'Expected value in {0} years',
  'roboAdvice.proposal.portfolio': 'Suggested portfolio',
  'roboAdvice.proposal.NoPortfolioData': 'No portfolio data',
  'roboAdvice.proposal.noCostData': 'No cost data',
  'roboAdvice.proposal.noHistoricalReturnData': 'No historical return data',
  'roboAdvice.proposal.broadAssetAllocation': 'Broad Asset Allocation',
  'roboAdvice.proposal.assetAllocation': 'Asset Allocation',
  'roboAdvice.proposal.fundAllocation': 'Fund Allocation',
  'roboAdvice.proposal.expectedPath': 'Expected Path',
  'roboAdvice.proposal.NoExpectedPathData': 'No expected path data',
  'roboAdvice.proposal.table': 'Table',
  'roboAdvice.proposal.graph': 'Graph',
  'roboAdvice.proposal.statistics': 'Statistics',
  'roboAdvice.proposal.year': 'Year',
  'roboAdvice.proposal.expectedValue': 'Expected Value',
  'roboAdvice.proposal.lowerBound': 'Lower Bound',
  'roboAdvice.proposal.upperBound': 'Upper Bound',
  'roboAdvice.proposal.upperAndLowerBound': 'Upper & lower bound',
  'roboAdvice.proposal.NoExpectedValueData': 'No expected value data',
  'roboAdvice.proposal.accumulatedDeposits': 'Accumulated Deposits',
  'roboAdvice.proposal.accumulatedReturns': 'Accumulated Returns',
  'roboAdvice.proposal.totalValue': 'Total Value',
  'roboAdvice.proposal.yourSavingPlan': 'Your saving plan',
  'roboAdvice.proposal.savingInYourBankAccount': 'Saving in your bank account',
  'roboAdvice.proposal.efficientFrontier': 'Efficient Frontier',
  'roboAdvice.proposal.NoEfficientFrontierData': 'No efficient frontier data',
  'roboAdvice.proposal.historicalReturn': 'Historical return',
  'roboAdvice.proposal.historicalReturn.table.return': 'Return',
  'roboAdvice.proposal.historicalReturn.table.risk': 'Performance',
  'roboAdvice.proposal.historicalReturn.table.performance': 'Risk',
  'roboAdvice.proposal.historicalReturn.3m': '3M',
  'roboAdvice.proposal.historicalReturn.ytd': 'YTD',
  'roboAdvice.proposal.historicalReturn.1y': '1Y',
  'roboAdvice.proposal.historicalReturn.3y': '3Y',
  'roboAdvice.proposal.historicalReturn.5y': '5Y',
  'roboAdvice.proposal.historicalReturn.10y': '10Y',
  'historicalReturn.historyIsNotLongEnough':
    'The history is not long enough to estimate requested return calculation',
  'historicalReturn.lastUpdated': 'Last updated',
  'historicalReturn.return': 'Return',
  'historicalReturn.graphDescription':
    'The graph below shows historical value development for the proposed portfolio {0}:',
  'historicalReturn.graphDescription.overLastYears': 'over the last {0} years',
  'historicalReturn.graphDescription.overLastMonths':
    'over the last {0} months',
  'historicalReturn.graphDescription.yearToDate': 'year to date',
  'roboAdvice.proposal.efficientFrontier.risk': 'Risk',
  'roboAdvice.proposal.efficientFrontier.expectedReturn': 'Expected return',
  'roboAdvice.proposal.efficientFrontier.low': 'Low',
  'roboAdvice.proposal.efficientFrontier.high': 'High',
  'roboAdvice.proposal.generateReport.selectComponents':
    'Select components to include in the report',
  'roboAdvice.proposal.generateReport.generateReport': 'Generate report',
  'roboAdvice.proposal.generateReport.analyticsComponents':
    'Analytics components',
  'roboAdvice.proposal.generateReport.attachments': 'Attachments',
  'roboAdvice.proposal.generateReport.all': 'All',
  'roboAdvice.proposal.generateReport.kiid': 'KIID documents',
  'roboAdvice.proposal.generateReport.investmentPlan': 'Investment plan',
  'roboAdvice.proposal.generateReport.portfolio': 'Portfolio',
  'roboAdvice.proposal.generateReport.expectations': 'Expectations',
  'roboAdvice.proposal.generateReport.expectedPath': 'Expected path',
  'roboAdvice.proposal.generateReport.expectedValue': 'Expected value',
  'roboAdvice.proposal.generateReport.cost': 'Cost',
  'roboAdvice.proposal.generateReport.efficientFrontier': 'Efficient frontier',
  'roboAdvice.proposal.generateReport.historicalReturn': 'Historical return',
  'roboAdvice.proposal.generateReport.customerAgreement': 'Customer agreement',
  'roboAdvice.proposal.generateReport.activeManagementAgreement':
    'Active management agreement',
  'roboAdvice.proposal.generateReport.kiids': 'KIIDs',
  'roboAdvice.proposal.generateReport.getPdfReportErrorMessage':
    'Error occured while loading pdf report',
  'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage':
    'Document {0} is not available for isin {1}',
  'roboAdvice.proposal.generateReport.kycCompany': 'KYC Company',
  'roboAdvice.proposal.generateReport.kycPerson': 'KYC Person',
  'roboAdvice.proposal.generateReport.productAttachments':
    'Product Attachments',
  'roboAdvice.proposal.generateAndCompleteAdviceDisabled':
    'You do not have permission to generate proposal or complete this advice',
  'roboAdvice.proposal.markAsComplete': 'Mark as Complete',
  'roboAdvice.proposal.warningMessage.newDataOwner':
    'New data has been added to the advice session and the PDF draft is no longer valid. Please generate a new report',
  'roboAdvice.proposal.warningMessage.newDataNotOwner':
    'New data was added by the owner of the advice session. Cannot generate proposal or download report',
  'roboAdvice.proposal.adviceSessionMarkedAsCompleted':
    'Advice session successfully marked as completed',
  'roboAdvice.clientInformation.generalInformation.header':
    'General information',
  'roboAdvice.clientInformation.generalInformation.tooltip':
    'This field is required',
  'roboAdvice.clientInformation.generalInformation.contactPerson':
    'Contact person',
  'roboAdvice.clientInformation.generalInformation.controlPerson':
    'Control person',
  'roboAdvice.clientInformation.generalInformation.controlPersonName':
    'Control person name',
  'roboAdvice.clientInformation.generalInformation.controlPersonSsn': 'SSN',
  'clientInformation.personGeneralInformation.accountNumber': 'Account number',
  'roboAdvice.clientInformation.generalInformation.controlPersonTitle': 'Title',
  'roboAdvice.clientInformation.generalInformation.beneficialOwner':
    'Beneficial owner',
  'roboAdvice.clientInformation.generalInformation.orgNumber.invalidValue':
    'Org. number should follow rules related to country',
  'roboAdvice.clientInformation.generalInformation.orgNumber.missingValue':
    'Can not get data from ENIN due to missing Org. number. Please enter an org. number in the client information page',
  'roboAdvice.clientInformation.experience.header':
    'Experience with the following:',
  'roboAdvice.clientInformation.clientClassification.header':
    'Client classification',
  'roboAdvice.clientInformation.clientClassification.title':
    'Client classification',
  'roboAdvice.clientInformation.clientClassifications.retailClient':
    'Retail client',
  'roboAdvice.clientInformation.clientClassifications.eligibleCounterparty':
    'Eligible Counterparty',
  'roboAdvice.clientInformation.clientClassifications.professionalClient':
    'Professional client',
  'roboAdvice.clientInformation.moneyOrigin.header':
    'What is the origin of the money?',
  'roboAdvice.clientInformation.moneyOrigin.title': 'Origin of the money',
  'roboAdvice.clientInformation.moneyOrigins.salary': 'Salary',
  'roboAdvice.clientInformation.moneyOrigins.heritage': 'Heritage',
  'roboAdvice.clientInformation.moneyOrigins.gift': 'Gift',
  'roboAdvice.clientInformation.moneyOrigins.gamblingLottery':
    'Gambling / lottery',
  'roboAdvice.clientInformation.moneyOrigins.realEstateSale':
    'Real estate sale',
  'roboAdvice.clientInformation.moneyOrigins.companyRevenues':
    'Company revenues',
  'roboAdvice.clientInformation.moneyOrigins.excessLiquidity':
    'Excess liquidity',
  'roboAdvice.clientInformation.moneyOrigins.other': 'Other',
  'roboAdvice.clientInformation.otherMoneyOriginPlaceholder': 'Comment here',
  'roboAdvice.clientInformation.clientRelationshipPurpose.header':
    'Purpose for client relationship',
  'roboAdvice.clientInformation.clientRelationshipPurpose.title':
    'Purpose for client relationship',
  'roboAdvice.clientInformation.clientRelationshipPurposePlaceholder':
    'State the purpose for the client relationship here',
  'roboAdvice.financialSituation.sufficientBufferEmptyValidationError':
    'Please select an option',
  'roboAdvice.financialSituation.monthlySurplusEmptyValidationError':
    'Please enter monthly surplus',
  'roboAdvice.financialSituation.sumAssets': 'Sum assets',
  'roboAdvice.financialSituation.realEstate': 'Real Estate',
  'roboAdvice.financialSituation.moneyInStockFunds': 'Money in stock/funds',
  'roboAdvice.financialSituation.valueOfCarBoat': 'Value of a car/boat',
  'roboAdvice.financialSituation.investmentPortfolio': 'Investment portfolio',
  'roboAdvice.financialSituation.cash': 'Cash',
  'roboAdvice.financialSituation.sumDebt': 'Sum debt',
  'roboAdvice.financialSituation.mortgage': 'Mortgage',
  'roboAdvice.financialSituation.carOrBoatLoan': 'Car or boat loan',
  'roboAdvice.financialSituation.studentLoan': 'Student loan',
  'roboAdvice.financialSituation.debt': 'Debt',
  'roboAdvice.financialSituation.shortTermDebtVsMonthlySurplus':
    'Short term debt vs monthly surplus',
  'roboAdvice.financialSituation.sufficientBuffer': 'Sufficient buffer',
  'roboAdvice.financialSituation.monthlySurplus': 'Monthly Surplus',
  'roboAdvice.financialSituation.netAssets': 'Net assets',
  'roboAdvice.financialSituation.liquidAssets': 'Liquid assets - credit card',
  'roboAdvice.financialSituation.debtToAssetRatio': 'Debt to asset ratio',
  'roboAdvice.financialSituation.generateRiskAssessment':
    'Generate Risk Assessment',
  'roboAdvice.financialSituation.someInputFieldsAreEmpty':
    'Some input fields are empty. Therefore, assessment is not approved.',
  'roboAdvice.financialSituation.assessment': 'Assessment',
  'roboAdvice.financialSituation.sufficientBuffer.yes': 'Yes',
  'roboAdvice.financialSituation.sufficientBuffer.no': 'No',
  'roboAdvice.financialSituation.assets': 'Assets',
  'roboAdvice.financialSituation.liquidity': 'Liquidity',
  'roboAdvice.financialSituation.investableAssets': 'Investable assets',
  'roboAdvice.financialSituation.accountingFigures': 'Accounting figures',
  'roboAdvice.financialSituation.investmentsListed': 'Investments - listed',
  'roboAdvice.financialSituation.investmentsUnlisted': 'Investments - unlisted',
  'roboAdvice.financialSituation.investmentsFunds': 'Investments - Funds',
  'roboAdvice.financialSituation.loansToCompanies': 'Loans to companies',
  'roboAdvice.financialSituation.other': 'Other',
  'roboAdvice.financialSituation.revenues': 'Revenues',
  'roboAdvice.financialSituation.costs': 'Costs',
  'roboAdvice.financialSituation.operationalResult': 'Operational result',
  'roboAdvice.financialSituation.preTaxResult': 'Pre-tax result',
  'roboAdvice.financialSituation.yearlyResult': 'Yearly result',
  'roboAdvice.financialSituation.sumEquity': 'Sum equity',
  'roboAdvice.financialSituation.shortTermDebt': 'Short-term debt',
  'roboAdvice.financialSituation.longTermDebt': 'Long-term debt',
  'roboAdvice.financialSituation.otherDebt': 'Other debt',
  'roboAdvice.financialSituation.requiredWorkingCapital':
    'Required working capital',
  'roboAdvice.financialSituation.annualSurplus': 'Annual surplus',
  'roboAdvice.financialSituation.requiredWorkingCapitalVsCash':
    'Required working capital vs cash',
  'roboAdvice.financialSituation.netInvestableAssets': 'Net investable assets',
  'roboAdvice.financialSituation.debtToInvestableAssetsRatio':
    'Debt to investable assets ratio',
  'roboAdvice.financialSituation.shortTermDebtVsCash':
    'Short-term debt vs cash',
  'roboAdvice.financialSituation.expectingFinancialChanges':
    'Are you expecting any changes to your financial situation in the coming period?',
  'roboAdvice.financialSituation.expectingFinancialChangesNote': 'Note',
  'roboAdvice.financialSituation.amountForAdvice': 'Amount for advice',
  'roboAdvice.financialSituation.amountForAdvice.type': 'Type',
  'roboAdvice.financialSituation.amountForAdvice.source': 'Source',
  'roboAdvice.financialSituation.amountForAdvice.amount': 'Amount',
  'roboAdvice.financialSituation.amountForAdvice.toAdvisory': 'To advisory',
  'roboAdvice.financialSituation.amountForAdvice.selectAssetClass':
    'Select asset class',
  'roboAdvice.financialSituation.amountForAdvice.granularModalTitle':
    'Assets for advisory - define asset classes',
  'roboAdvice.financialSituation.amountForAdviceTable.instrument': 'Instrument',
  'roboAdvice.financialSituation.amountForAdviceTable.originalValue':
    'Original value',
  'roboAdvice.financialSituation.amountForAdviceTable.tooltip':
    'Please enter granular details into the Assets table in order to continue assigning asset classes in the Amount for Advice table.',
  'roboAdvice.financialSituation.amountForAdviceTable.externalHoldings':
    'External holdings',
  'roboAdvice.financialSituation.amountForAdviceTable.internalHoldings':
    'Internal holdings',
  'roboAdvice.financialSituation.eninDataLoadSuccessMessage':
    'ENIN data loaded successfully',
  'roboAdvice.financialSituation.readENINDataErrorMessage':
    'An error occurred loading ENIN data',
  'roboAdvice.financialSituation.accountsSync.modal.header':
    'Refresh account data',
  'roboAdvice.financialSituation.accountsSync.modal.content':
    'Are you sure you want to refresh data from accounts? If you confirm, amount for advice and mapping of instruments to asset classes will be reset.',
  'roboAdvice.financialSituation.accountsSync.errorMessage':
    'An error occured when loading accounts data. Please fill manually.',
  'roboAdvice.saveAdviceSessionDataErrorMessage':
    'Error occured while saving advice session data',
  'roboAdvice.saveAdviceSessionDataErrorNotPermitted':
    'Only session owner can make changes to the session',
  'roboAdvice.orderExecution.instrumentsToOrder': 'Instruments to order',
  'roboAdvice.orderExecution.initialDeposit': 'Initial Deposit',
  'roboAdvice.orderExecution.monthlyDeposit': 'Monthly Deposit',
  'roboAdvice.orderExecution.selectedInstruments': 'Selected instruments',
  'roboAdvice.orderExecution.generateOrder': 'Generate order',
  'roboAdvice.orderExecution.orderDocument': 'Order document',
  'roboAdvice.orderExecution.instrumentsList.summaryRow': 'Summary',
  'roboAdvice.orderExecution.readInstrumentsErrorMessage':
    'Error occured while loading instruments',
  'roboAdvice.orderExecution.readOrderExecutionCostDataErrorMessage':
    'Error occured while loading cost data',
  'roboAdvice.orderExecution.readInstrumentsDataErrorMessage':
    'Error occurred while loading instruments data',
  'roboAdvice.orderExecution.searchInstrumentsNotFound':
    'No Financial Instruments Found',
  'roboAdvice.orderExecution.searchInstrumentsNotFoundSubheader':
    'Please refine your search criteria',
  'roboAdvice.orderExecution.pdfGeneration.button': 'Generate PDFv2',
  'roboAdvice.orderExecution.pdfGeneration.modalHeader': 'Create PDF',
  'roboAdvice.orderExecution.pdfGeneration.modalTemplateSelection':
    'Select template',
  'roboAdvice.orderExecution.pdfGeneration.modalSectionSelection':
    'Select sections to include',
  'roboAdvice.orderExecution.pdfGeneration.modalAttachmentSelection':
    'Select attachments to include',
  'roboAdvice.orderExecution.pdfGeneration.modalGenerateReport':
    'Generate report',
  'portfolioChart.default.portfolio': 'Portfolio',
  'portfolioChart.roboFront.fundAndWeight': 'Fund and weight',
  'portfolioChart.roboFront.isin': 'ISIN',
  'expectedPathChart.default.expectedValue': 'Expected Value',
  'expectedPathChart.default.upperAndLowerBound': 'Upper & lower bound',
  'expectedValueChart.default.yourSavingPlan': 'Your saving plan',
  'expectedValueChart.default.savingInYourBankAccount':
    'Saving in your bank account',
  'roboAdvice.advisory.buildPortfolio.enterPortfolioName':
    'Please enter portfolio name',
  'costChart.yearsTable.deposits': 'Deposits',
  'costChart.yearsTable.expValueBeforeCost': 'Expected value before cost',
  'costChart.yearsTable.expValueAfterCost': 'Expected value after cost',
  'costChart.yearsTable.reductionInReturn': 'Reduction in return',
  'costChart.yearsTable.reductionInReturnPercent': 'Reduction in return (%)',
  'costChart.yearsTable.expValueAfterCostPercent':
    'Expected return after cost (%)',
  'costChart.yearsTable.expectedReturn': 'Expected return',
  'costChart.costTable.entryFee': 'Entry fee',
  'costChart.costTable.managementFee': 'Management fee',
  'costChart.costTable.transactionCost': 'Transaction Cost',
  'costChart.costTable.totalCost': 'Total costs',
  'costChart.summaryTable.totalCostFund': 'Total cost fund',
  'costChart.summaryTable.advisoryFee': 'Advisory fee',
  'costChart.costTable.fundManagementFee': 'Fund management fee',
  'costChart.costTable.fundReturnCommissionPaidToClient':
    'Fund return commission paid to client',
  'costChart.costTable.fundReturnCommissionPaidToAdvisor':
    'Fund return commission paid to advisor',
  'costChart.costTable.fundTransactionCost': 'Fund transaction cost',
  'costChart.costTable.performanceFee': 'Performance fee',
  'costChart.costTable.platformFee': 'Platform fee',
  'costChart.costTable.insuranceCost': 'Insurance cost',
  'costChart.costTable.custodyFee': 'Custody fee',
  'costChart.costTable.totalPortfolioFee': 'Total portfolio fee',
  'costChart.costTable.fundPurchaseFee': 'Fund purchase fee',
  'costChart.costTable.advisoryPurchaseFee': 'Advisory purchase fee',
  'costChart.costTable.returnCommissionPaidToAdvisor':
    'Return commission paid to advisor',
  'costChart.costTable.annualReturnCommissionPaidToAdvisor':
    'Annual return commission paid to advisor',
  'costChart.costTable.ongoingFees': 'Ongoing fees',
  'costChart.costTable.advisoryOnboardingFee': 'Advisory onboarding fee',
  'costChart.costTable.advisoryPurchasingFee': 'Advisory purchasing fee',
  'portfolioChart.title.targetAllocation': 'Target allocation',
  'portfolioChart.title.actualAllocation': 'Actual allocation',
  'costChart.summaryTable.totalCostPortfolio': 'Total cost portfolio',
  'roboAdvice.financialSituation.assets.error':
    'Fill at least 1 field in assets section',
  'roboAdvice.financialSituation.debt.error':
    'Fill at least 1 field in debt section',
  'roboAdvice.advisory.savingsPlan.noValuesError':
    'Please enter an amount for monthly saving and/or initial investment',
  'roboAdvice.advisory.savingsPlan.notSuitableSavingPlanError':
    'Suggested saving plan is not suitable, based on the customer’s financial situation',
  'admin.trashCan.clients.table.type': 'Type',
  'admin.trashCan.clients.table.client': 'Client',
  'admin.trashCan.clients.table.advisor': 'Advisor',
  'admin.trashCan.clients.table.lastActivityDate': 'Last activity date',
  'admin.trashCan.clients.table.softDeleteDate': 'Soft-delete date',
  'admin.trashCan.clients.table.noClients': 'No clients',
  'admin.trashCan.clients.readClientsErrorMessage':
    'Error occurred while loading clients',
  'admin.trashCan.clients.deleteClientsConfirmationMessage_1':
    'Are you sure you want to delete marked clients?',
  'admin.trashCan.clients.deleteClientsConfirmationMessage_2':
    'This will also delete all connected advice sessions.',
  'admin.trashCan.clients.restoreClientsConfirmationMessage':
    'Are you sure you want to restore marked clients?',
  'admin.trashCan.clients.deleteClientsErrorMessage':
    'Error occurred while deleting clients',
  'admin.trashCan.clients.deleteClientsSuccessMessage':
    'All clients deleted successfully',
  'admin.trashCan.clients.deleteClientsHalfSuccessMessage':
    'Deleted {0} clients, but {1} failed',
  'admin.trashCan.clients.deleteClientsFailedMessage':
    'Deletion of clients failed',
  'admin.trashCan.clients.restoreClientsSuccessMessage':
    'Clients restored successfully',
  'admin.trashCan.clients.restoreClientsErrorMessage':
    'Error occurred while restoring clients',
  'admin.trashCan.adviceSessions.table.adviceSession': 'Advice session',
  'admin.trashCan.adviceSessions.table.client': 'Client',
  'admin.trashCan.adviceSessions.table.advisor': 'Advisor',
  'admin.trashCan.adviceSessions.table.lastActivityDate': 'Last activity date',
  'admin.trashCan.adviceSessions.table.softDeleteDate': 'Soft-delete date',
  'admin.trashCan.adviceSessions.table.noAdviceSessions': 'No advice sessions',
  'admin.trashCan.adviceSessions.deleteAdviceSessionsConfirmationMessage':
    'Are you sure you want to delete marked advice session?',
  'admin.trashCan.adviceSessions.restoreAdviceSessionsConfirmationMessage_1':
    'Are you sure you want to restore marked advice sessions?',
  'admin.trashCan.adviceSessions.restoreAdviceSessionsConfirmationMessage_2':
    'This will also lead to restoring of connected clients.',
  'roboAdvice.purposeAndRisk.purposeTable.goal': 'Goal',
  'roboAdvice.purposeAndRisk.purposeTable.years': 'Years',
  'roboAdvice.purposeAndRisk.purposeTable.deleteConfirmationMessage':
    'Are you sure you want delete the goal?',
  'roboAdvice.purposeAndRisk.purposeTable.deleteSuccessMessage': 'Goal deleted',
  'roboAdvice.purposeAndRisk.addNewGoal': 'Add new goal',
  'roboAdvice.purposeAndRisk.editGoal': 'Edit goal',
  'roboAdvice.purposeAndRisk.purposePopup.optionalDescription':
    'Description (optional)',
  'roboAdvice.purposeAndRisk.purposePopup.selectOptionalIcon':
    'Select icon (optional)',
  'roboAdvice.purposeAndRisk.purposePopup.namePlaceholder': 'Enter Name here',
  'roboAdvice.purposeAndRisk.purposePopup.descriptionPlaceholder':
    'Describe your goal here',
  'roboAdvice.purposeAndRisk.purposePopup.capitalNeedPlaceholder':
    'Enter Value Here',
  'roboAdvice.purposeAndRisk.purposePopup.dateFormatPlaceholder': 'YYYY',
  'roboAdvice.purposeAndRisk.purposePopup.monthlyWithdrawalPlaceholder':
    'Enter Your Goal Here',
  'roboAdvice.advisorNotes': 'Advisor notes',
  'roboAdvice.advisory.reasoningForAdvice': 'Reasoning for advice',
  'roboAdvice.advisory.reasoningForAdvice.placeholder': 'Advisor notes',
  'roboAdvice.advisory.reasoningForAdvice.infoTooltip': '',
  'roboAdvice.advisory.minimumCharacter': 'Minimum 25 characters',
  'roboAdvice.advisory.portfolioTable.riskScore': 'Risk score',
  'roboAdvice.advisory.portfolioTable.monthlyDeposit': 'Monthly deposit',
  'roboAdvice.advisory.portfolioTable.firstDeposit': 'First deposit',
  'shared.characters': 'characters',
  'shared.maximum': 'Maximum',
  'roboAdvice.proposal.goals': 'Goals',
  'roboAdvice.proposal.notes': 'Notes',
  'roboAdvice.proposal.notes.placeholder': 'Advisor notes',
  'roboAdvice.proposal.notes.infoTooltip': '',
  'roboAdvice.adviceSession.purposeAndRisk.createGoalErrorMessage':
    'Error occurred while creating goal',
  'roboAdvice.adviceSession.purposeAndRisk.createGoalErrorNotPermitted':
    'Only session owner can make changes to the session',
  'roboAdvice.adviceSession.purposeAndRisk.saveGoalSuccessMessage':
    'Goal saved successfully',
  'roboAdvice.adviceSession.purposeAndRisk.deleteGoalErrorMessage':
    'Error occurred while deleting goal',
  'roboAdvice.adviceSession.purposeAndRisk.deleteGoalSuccessMessage':
    'Goal removed',
  'roboAdvice.adviceSession.purposeAndRisk.updateGoalErrorMessage':
    'Error occurred while updating goal',
  'roboAdvice.purposeAndRisk.withdrawalPlan': 'Withdrawal plan',
  'roboAdvice.purposeAndRisk.monthlyWithdrawal': 'Monthly withdrawal',
  'roboAdvice.purposeAndRisk.oneTimeWithdrawal': 'One time withdrawal',
  'roboAdvice.purposeAndRisk.noPlannedWithdrawal': 'No planned withdrawal',
  'roboAdvice.purposeAndRisk.capitalNeed': 'Capital need',
  'roboAdvice.purposeAndRisk.withdrawalStart': 'Withdrawal start',
  'roboAdvice.purposeAndRisk.withdrawalEnd': 'Withdrawal end',
  'roboAdvice.purposeAndRisk.withdrawalPlanEmptyValidationError':
    'Please select withdrawal plan',
  'roboAdvice.purposeAndRisk.capitalNeedValidationError':
    'Please enter an amount for capital need',
  'roboAdvice.purposeAndRisk.monthlyWithdrawalValidationError':
    'Please enter an amount for monthly withdrawal',
  'roboAdvice.purposeAndRisk.withdrawalDateValidationError':
    'Withdrawal end must be bigger than withdrawal start',
  'roboAdvice.purposeAndRisk.withdrawalDateFormatValidationError':
    'Please enter a date following the format YYYY',
  'roboAdvice.proposal.cashflow': 'Cashflow',
  'roboAdvice.proposal.cashflow.frequency': 'Frequency',
  'roboAdvice.proposal.cashflow.withdrawal': 'Withdrawal',
  'roboAdvice.proposal.cashflow.withdrawals': 'Withdrawals',
  'roboAdvice.proposal.cashflow.deposits': 'Deposits',
  'shared.aggregatedPortfolio': 'Aggregated portfolio',
  'roboAdvice.advisory.selectYourThemes': 'Select your themes (optional)',
  'roboAdvice.proposal.noCashflowData': 'No cashflow data',
  'cashflow.capitalNeed': 'Capital need',
  'cashflow.estimatedGoalAchievement': 'Estimated goal achievement',
  'cashflow.savingPlan': 'Saving plan',
  'cashflow.savingPlanWithNoReturn': 'Saving plan with no return',
  'roboAdvice.knowledgeAndExperience.advancedThemeSuitabilityAssessment.training':
    'Training',
  'roboAdvice.knowledgeAndExperience.advancedThemeSuitabilityAssessment.confirmTraining':
    'I understand',
  'roboAdvice.adviceSession.knowledgeAndExperience.readAdvancedSuitabilityConfigErrorMessage':
    'Error occured while loading suitability config',
  'roboAdvice.adviceSession.knowledgeAndExperience.readAdvancedSuitabilityStatusErrorMessage':
    'Error occured while loading suitability status',
  'roboAdvice.clientInformation.contacts': 'Contacts',
  'roboAdvice.clientInformation.contacts.name': 'Name',
  'roboAdvice.clientInformation.contacts.email': 'E-mail',
  'roboAdvice.clientInformation.contacts.phone': 'Phone',
  'roboAdvice.clientInformation.contacts.role': 'Role',
  'roboAdvice.clientInformation.contacts.signRight': 'Sign. right',
  'roboAdvice.clientInformation.contacts.ubo': 'UBO',
  'roboAdvice.clientInformation.contacts.deleteConfirmationMessage':
    'Are you sure you want delete the contact?',
  'roboAdvice.clientInformation.contacts.errorMessage':
    'Please fill missing text fields',
  'roboAdvice.advisorInformation.meetingPlaceRequiredError':
    'Please select meeting place',
  'roboAdvice.advisorInformation.meetingPlaceOtherRequiredError':
    'Please enter other meeting place',
  'roboAdvice.advisorInformation.whoInitiatedMeetingRequiredError':
    'Please select who initiated meeting',
  'roboAdvice.client.kyc': 'KYC',
  'roboAdvice.kyc.clientRelationshipPurposePlaceholder':
    'State the purpose here',
  'roboAdvice.kyc.clientRelationshipPurposeLabel':
    'Purpose for client relationship',
  'roboAdvice.kyc.pepLabel': 'Is the client a PEP?',
  'roboAdvice.kyc.pepHeader': 'PEP',
  'roboAdvice.kyc.sanctionsHeader': 'Sanctions',
  'roboAdvice.kyc.clientRelationshipPurposeRequiredError':
    'Please enter purpose for client relationship',
  'roboAdvice.kyc.pepRequiredError': 'Please select answer',
  'roboAdvice.kyc.sanctionsRequiredError': 'Please select answer',
  'roboAdvice.kyc.advisorNoteRequiredError': 'Please enter advisor note',
  'roboAdvice.kyc.signatureRight': 'Signature right',
  'roboAdvice.kyc.ubo': 'UBO',
  'roboAdvice.kyc.identityType': 'Identity type',
  'roboAdvice.kyc.identity': 'Identity',
  'roboAdvice.kyc.country': 'Country',
  'roboAdvice.kyc.countryOfResidence': 'Country of residence',
  'roboAdvice.kyc.ownership': 'Ownership, %',
  'roboAdvice.kyc.countryPlaceholder': 'Select',
  'roboAdvice.kyc.signatureRight.deleteConfirmationMessage':
    'Are you sure you want delete the signature right?',
  'roboAdvice.kyc.ubo.deleteConfirmationMessage':
    'Are you sure you want delete the UBO?',
  'roboAdvice.kyc.signatureRight.errorMessage':
    'Please fill missing text fields or delete uncompleted row',
  'roboAdvice.kyc.ubo.errorMessage':
    'Please fill missing text fields or delete uncompleted row',
  'roboAdvice.kyc.ubo.ownershipErrorMessage': 'Ownership exceeds 100%',
  'roboAdvice.kyc.automaticClientClassification.clientClassification.header':
    'Client Classification',
  'roboAdvice.kyc.automaticClientClassification.clientClassification.description':
    'Is the client company that have been granted permission to, or are regulated by law to carry out activities on the financial markets',
  'roboAdvice.kyc.automaticClientClassification.automaticClassification.header':
    'Automatic Classification',
  'roboAdvice.kyc.automaticClientClassification.automaticClassification.description':
    'Automatic generated from the form above',
  'roboAdvice.kyc.automaticClientClassification.selectClientTypeAbove':
    'Select client type above',
  'roboAdvice.kyc.automaticClientClassification.noneOfTheAbove':
    'None of the above',
  'roboAdvice.kyc.automaticClientClassification.nonProfessionalInvestor':
    'Non-professional Investor',
  'roboAdvice.kyc.automaticClientClassification.professionalInvestor':
    'Professional Investor',
  'roboAdvice.kyc.automaticClientClassification.professionalChecklist.header':
    'Professional by request: Advisor Check-List',
  'roboAdvice.kyc.automaticClientClassification.professionalChecklist.description':
    'An investor who has been classified as non-professional can request to be re-classified to professional investor if following criteria are met',
  'roboAdvice.kyc.automaticClientClassification.nonProfessionalChecklist.header':
    'Non-professional by Request Advisor Check-List',
  'roboAdvice.kyc.automaticClientClassification.nonProfessionalChecklist.description':
    'An investor who has been classified as professional can request to be re-classified to non-professional investor if following criteria are met',
  'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.header':
    'Re-classification request documentation',
  'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.description':
    'Mandatory to fill in, if automatic classification is changed',
  'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.error':
    'Please enter reclassification request documentation',
  'roboAdvice.kyc.automaticClientClassification.resetClientClassification.header':
    'Reset Client Classification',
  'roboAdvice.kyc.automaticClientClassification.resetClientClassification.description':
    'Are you sure you would like to reset client classification? If yes, re-classification and documentation provided in this section will be removed. ',
  'roboAdvice.kyc.automaticClientClassification.investorTypeError':
    'Please fill client classification',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.title':
    'Investment Firm',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.header':
    'Is the client authorized, or regulated, or operate as any of the bellow:',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option1':
    'Credit institution',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option2':
    'Investment firm',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option3':
    'Other Financial Institution',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option4':
    'Insurance company',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option5':
    'Asset Management Company',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option6':
    'Commodity and commodity derivatives dealers',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option7':
    'Other institutional investor',
  'roboAdvice.kyc.automaticClientClassification.investmentFirm.option8':
    'Locals',
  'roboAdvice.kyc.automaticClientClassification.largeCorporation.title':
    'Large Corporation',
  'roboAdvice.kyc.automaticClientClassification.largeCorporation.header':
    'Is any of the following statement true for the client:',
  'roboAdvice.kyc.automaticClientClassification.largeCorporation.option1':
    'Balance > 20 mill EUR',
  'roboAdvice.kyc.automaticClientClassification.largeCorporation.option2':
    'Net sales > 40 mill EUR',
  'roboAdvice.kyc.automaticClientClassification.government.title': 'Government',
  'roboAdvice.kyc.automaticClientClassification.government.header':
    'Is the client national or regional government?',
  'roboAdvice.kyc.automaticClientClassification.government.option1':
    'Public debt manager',
  'roboAdvice.kyc.automaticClientClassification.government.option2':
    'Central bank',
  'roboAdvice.kyc.automaticClientClassification.government.option3':
    'World Bank',
  'roboAdvice.kyc.automaticClientClassification.government.option4': 'The IMF',
  'roboAdvice.kyc.automaticClientClassification.government.option5': 'The ECB',
  'roboAdvice.kyc.automaticClientClassification.government.option6': 'The EIB',
  'roboAdvice.kyc.automaticClientClassification.institutionalInvestor.title':
    'Institutional Investor',
  'roboAdvice.kyc.automaticClientClassification.institutionalInvestor.header':
    'Is the client?',
  'roboAdvice.kyc.automaticClientClassification.institutionalInvestor.option1':
    'Other institutional investors whose main activity is to invest in financial instruments, including entities dedicated to the securitisation of assets or other financing transactions',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem1':
    'Have you received a written request from the client to reclassify as professional',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem2':
    'Do the client meet two of the three criteria: 1) the client has carried out transactions in significant size, on the relevant market at an average frequency of 10 per quarter over the previous four quarters 2) the size of the client’s financial instrument portfolio, defined as including cash deposits and financial instruments exceeds EUR 500 000 3) the client works or has worked in the financial sector for at least one year in a professional position, which requires knowledge of the transactions or services envisaged',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem3':
    'Have the client received a clear written warning of the protections and investor compensation rights they may lose',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem4':
    'Has the client stated in writing, in a separate document from the contract, that they are aware of the consequences of losing such protections',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem5':
    'Have all reasonable steps been taken to ensure that the client requesting to be treated as a professional client meets the relevant requirements.',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.professionalItem6':
    'Have you done an assessment to be sure that the client has the necessary experience, knowledge and expertise to enter into the available investment services, transactions or financial instruments and understand the risk connected to these',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.nonProfessionalItem1':
    'Investor has requested non-professional treatment',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.nonProfessionalItem2':
    'Investment firm agrees to the request of re-classification',
  'roboAdvice.kyc.automaticClientClassification.checklistModal.nonProfessionalItem3':
    'Agreement about re-classification has been executed',
  'roboAdvice.kyc.automaticClientClassification.simpleModal.header':
    'Re-classification by request',
  'roboAdvice.kyc.automaticClientClassification.simpleModal.description':
    'Choose new classification',
  'roboAdvice.kyc.enin.Flag': 'Flag',
  'roboAdvice.kyc.enin.companyFlags': 'Company Flags',
  'roboAdvice.kyc.enin.flagName': 'Flag Name',
  'roboAdvice.kyc.enin.noDataMessage': 'There is no data to display',
  'roboAdvice.kyc.enin.possibleChangeOwnership': 'Possible change ownership',
  'roboAdvice.kyc.enin.beneficialOwnershipChangeOrNewBeneficialOwners':
    'Beneficial ownership change or new beneficial owners',
  'roboAdvice.kyc.enin.samePersonOwnsMoreThanHalfAndIsCeoAndBoardChairman':
    'Same person owns more than half and is ceo and board chairman',
  'roboAdvice.kyc.enin.anyDirectOwnershipChangeNorwegianShareRegistry':
    'Any direct ownership change norwegian share registry',
  'roboAdvice.kyc.enin.auditorChangeAfterAccountsWithAuditorNote':
    'Auditor change after accounts with auditor note',
  'roboAdvice.kyc.enin.auditorResignedAfterAccountsWithAuditorNote':
    'Auditor resigned after accounts with auditor note',
  'roboAdvice.kyc.enin.completeOwnershipChangeDirectOwnershipPreviousYear':
    'Complete ownership change direct ownership previous year',
  'roboAdvice.kyc.enin.completeOwnershipChangeUltimateOwnershipPreviousYear':
    'Complete ownership change ultimate ownership previous year',
  'roboAdvice.kyc.enin.beneficialOwnershipChange':
    'Beneficial ownership change',
  'roboAdvice.kyc.enin.legalRights': 'Legal Rights',
  'roboAdvice.kyc.enin.currentRoles': 'Current Roles',
  'roboAdvice.kyc.enin.noDataToPresent': 'No data to present',
  'roboAdvice.kyc.enin.dataOverrideMessage':
    'Are you sure you want to override existing values?',
  'roboAdvice.kyc.enin.ceo': 'CEO',
  'roboAdvice.kyc.enin.boardChairman': 'Chairman of the board',
  'roboAdvice.kyc.enin.boardMember': 'Board member',
  'roboAdvice.kyc.ssn': 'SSN',
  'roboAdvice.proposal.generateReport.report': 'Report',
  'roboAdvice.proposal.generateReport.includeReport': 'Include report',
  'roboAdvice.advisory.productPlatform.title': 'Product Platform',
  'roboAdvice.advisory.productPlatform.reasoningForSelection':
    'Reasoning for selection',
  'roboAdvice.advisory.productPlatform.reasoningForSelectionPlaceholder':
    'Please leave an explanation on using different options than recommended',
  'roboAdvice.advisory.productPlatform.reasoningForSelection.infoTooltip': '',
  'roboAdvice.advisory.productPlatform.preferences': 'Preferences',
  'roboAdvice.advisory.productPlatform.recommendation': 'Recommendation',
  'roboAdvice.advisory.productChooser.notAvailableTranslation': 'Not available',
  'roboAdvice.advisory.productChooser.notRecommendedTranslation':
    'Not recommended',
  'roboAdvice.advisory.productChooser.recommendedTranslation': 'Recommended',
  'roboAdvice.advisory.productChooser.platformName1': 'Platform name 1',
  'roboAdvice.advisory.productChooser.platformName2': 'Platform name 2',
  'roboAdvice.advisory.productChooser.platformName3': 'Platform name 3',
  'roboAdvice.advisory.productChooser.platformName4': 'Platform name 4',
  'roboAdvice.advisory.productChooser.platformName5': 'Platform name 5',
  'roboAdvice.advisory.productChooser.platformName6': 'Platform name 6',
  'roboAdvice.advisory.productChooser.platformName7': 'Platform name 7',
  'roboAdvice.advisory.productChooser.platformName8': 'Platform name 8',
  'roboAdvice.advisory.productChooser.platformName9': 'Platform name 9',
  'roboAdvice.advisory.productChooser.platformName10': 'Platform name 10',
  'roboAdvice.advisory.productChooser.platformNameMissingError':
    'Missing platform name',
  'roboAdvice.advisory.allocatedAmount.header': 'Allocated amount',
  'roboAdvice.advisory.allocatedAmount.internalPortfolio':
    'Internal portfolio to advisory',
  'roboAdvice.advisory.allocatedAmount.availableForAddOn':
    'Available for add on',
  'tools.holisticView': 'Holistic View',
  'tools.holisticView.addClient': '+ Add Client',
  'tools.holisticView.client': 'Client',
  'tools.holisticView.adviceSession': 'Advice Session',
  'tools.holisticView.addCustomerForHolisticOverview':
    'Add customer for holistic overview',
  'tools.holisticView.type': 'Type',
  'tools.holisticView.name': 'Name',
  'tools.holisticView.confirm': 'Confirm',
  'tools.holisticView.removeCustomerConfirmation':
    'Are you sure you want remove the customer?',
  'tools.breadcrumbs': 'Tools',
  'tools.holisticView.noClientsFound': 'No clients found',
  'tools.holisticView.noAdviceSessions': 'No advice sessions',
  'tools.holisticView.getAdviceSessionsError':
    'Error occurred while retrieving advice sessions.',
  'tools.holisticView.savingPlans': 'Saving plans',
  'tools.holisticView.withdrawalPlans': 'Withdrawal plans',
  'tools.holisticView.getGoalsError': 'Error occured while retrieving goals.',
  'cashflow.expectedValue': 'Expected value',
  'financialSituationAggregatedChart.aggregated': 'Aggregated',
  'financialSituationAggregatedChart.totalAssets': 'Total assets',
  'financialSituationAggregatedChart.totalDebt': 'Total debt',
  'tools.holisticView.assetsDrilldown': 'Assets - drilldown',
  'tools.holisticView.getRiskScoresError':
    'Error occured while retrieving risk scores.',
  'tools.holisticView.notEnoughData':
    'There is not enough data available, please update the advice session.',
  'tools.holisticView.noDataDisplayed': 'No data displayed',
  'tools.holisticView.pleaseAddClient': 'Please add client to show data',
  'tools.holisticView.equityShare': 'Equity share',
  'tools.holisticView.returnToAdvisorySession': 'Return to advisory session',
  'tools.savingCalculator': 'Saving Calculator',
  'roboAdvice.proposal.cost.adjustFees': 'Adjust fees:',
  'roboAdvice.proposal.orderSummary': 'Order summary',
  'roboAdvice.proposal.orderSummary.oneTimeOrders': 'One Time Orders',
  'roboAdvice.proposal.orderSummary.buy': 'Buy',
  'roboAdvice.proposal.orderSummary.fund': 'Fund',
  'roboAdvice.proposal.orderSummary.switch': 'Switch',
  'roboAdvice.proposal.orderSummary.oldFund': 'Old Fund',
  'roboAdvice.proposal.orderSummary.newFund': 'New Fund',
  'roboAdvice.proposal.orderSummary.sell': 'Sell',
  'roboAdvice.proposal.orderSummary.monthlyOrders': 'Monthly Orders',
  'roboAdvice.proposal.orderSummary.whereMoneyComeFrom':
    'Where does the money come from?',
  'roboAdvice.proposal.orderSummary.portfolioSize': 'Portfolio Size',
  'roboAdvice.proposal.orderSummary.depositsAndExternalHoldings':
    'Deposits and external holdings',
  'roboAdvice.proposal.orderSummary.currentAccounts': 'Current accounts',
  'roboAdvice.proposal.orderSummary.buyOrders': 'Buy orders',
  'roboAdvice.proposal.orderSummary.noData': 'No order summary data',
  'roboAdvice.sustainability.veryImportant': 'Very important',
  'roboAdvice.sustainability.important': 'Important',
  'roboAdvice.sustainability.moderatelyImportant': 'Moderately Important',
  'roboAdvice.sustainability.slightlyImportant': 'Slightly Important',
  'roboAdvice.sustainability.notImportant': 'Not Important',
  'roboAdvice.sustainability.undecided': 'Undecided',
  'roboAdvice.sustainability.additionalInformation': 'Additional information',
  'roboAdvice.sustainability.alignmentError':
    'Please provide answers to all questions regarding sustainability preferences. Sustainability assessment is required to complete the advisory process.',
  'roboAdvice.sustainability.genericAssessment.header':
    'Sustainability preferences',
  'roboAdvice.sustainability.genericAssessment.description':
    'To what extent is it important to you that your sustainability preferences are assessed and taken into consideration in the investment proposal, even though it might limit the universe of potential investment products.',
  'roboAdvice.sustainability.genericAssessment.answerNegative':
    'The proposal may include products that make sustainable investments and consider principal adverse impact. However, I do not wish to limit the product range based on these criteria.',
  'roboAdvice.sustainability.genericAssessment.answerPositive':
    'This is important to me, and I want to have my sustainability preferences assessed more closely.',
  'roboAdvice.sustainability.doYouWantToMarkAsAdapted':
    'Do you want to mark this preference as adapted?',
  'roboAdvice.sustainability.sustainabilityPreferencesAdaptedMessage':
    'Sustainability preferences has been adapted',
  'roboAdvice.sustainability.markedAsAdaptedTooltip':
    'Marked as adapted, click to remove',
  'roboAdvice.sustainability.valueAdaptedQuestionErrorListMessage':
    'Missing response to adapted sustainability preference confirmation',
  'roboAdvice.sustainability.valueAdaptedQuestionErrorAlertMessage':
    'Please select the answer to the following question',
  'roboAdvice.sustainability.investorHadNoSustainabilityPreferences':
    'Sustainability alignment of the portfolio cannot be assessed as the investor has not indicated any preferences.',
  'roboAdvice.proposal.sustainability': 'Sustainability',
  'roboAdvice.proposal.sustainabilityAssessmentAlignment':
    'Sustainability Assessment Alignment',
  'roboAdvice.proposal.sustainability.noData': 'No sustainability data',
  'roboAdvice.proposal.sustainability.weightedTable.fund': 'Fund',
  'roboAdvice.proposal.sustainability.weightedTable.alignment': 'Alignment',
  'roboAdvice.proposal.sustainability.weightedTable.portfolioScore':
    'Portfolio score',
  'roboAdvice.proposal.sustainability.horizontalBar.yourScore': 'Your score:',
  'roboAdvice.proposal.sustainability.horizontalBar.portfolio': 'Portfolio:',
  'roboAdvice.proposal.sustainability.standardTable.myPreference':
    'My preference',
  'roboAdvice.proposal.sustainability.preferenceCriteriaAlignment':
    'Preference Criteria Alignment',
  'roboAdvice.proposal.sustainability.noDataAvailable':
    'No sustainability data available',
  'roboAdvice.proposal.cost.overrideCostModal.confirmation':
    'Are you sure you want to leave without saving?',
  'roboAdvice.proposal.transactionList.transactionList': 'Transaction list',
  'roboAdvice.proposal.transactionList.externalHoldings':
    'External holdings and cash',
  'roboAdvice.proposal.transactionList.internalHoldings': 'Internal holdings',
  'roboAdvice.proposal.transactionList.table.targetValue': 'Target value',
  'roboAdvice.proposal.transactionList.table.transactionValue':
    'Transaction value',
  'roboAdvice.proposal.transactionList.table.action': 'Action',
  'roboAdvice.proposal.transactionList.table.keep': 'Keep',
  'roboAdvice.clientList.sessionBlocked':
    'The advice is completed and therefore blocked, please make a copy to do adjustments',
  'roboAdvice.adviceSession.statisticsTable.item': 'Item',
  'roboAdvice.client.missingInformation':
    'Missing information in {0}. Fill required fields to proceed',
  'roboAdvice.orderExecution.searchInstrumentName': 'Search instrument name',
  'roboAdvice.orderExecution.searchISIN': 'Search ISIN',
  'autocomplete.noResults': 'No results',
  'roboAdvice.advisory.portfolio.benchmark': 'Benchmark',
  'shared.description': 'Description',
  'roboAdvice.advisory.portfolio.benchmark.header':
    'Information about benchmark',
  'roboAdvice.reportGeneration.selectTemplate': 'Select template',
  'roboAdvice.reportGeneration.sectionToInclude': 'Select sections to include',
  'roboAdvice.reportGeneration.attachmentsToInclude':
    'Select attachments to include',
  'roboAdvice.reportGeneration.generateNewPdfProposal':
    'Generate new pdf proposal',
  'roboAdvice.errors.showMe': 'Show me',
  'roboAdvice.errors.nextButtonError': 'To continue you need to fix {0} errors',
  'roboAdvice.errors.missingAnswer': 'Missing answer to {0}',
  'roboAdvice.errors.missingAnswerAlert': 'Please answer the question below',
  'roboAdvice.errors.missingGoal': 'No goal added',
  'roboAdvice.errors.missingGoalAlert': 'Please add a goal to the saving plan',
  'roboAdvice.errors.missingAdvancedSuitability': 'Missing answer(s) in {0}',
  'roboAdvice.errors.missingAdvancedSuitabilityAlert':
    'Please select an answer to all rows in the table below',
  'roboAdvice.errors.missingInput': 'Missing input to {0}',
  'roboAdvice.errors.missingInputAlert': 'Missing input to {0}',
  'roboAdvice.errors.missingField': 'Fill one field in {0}',
  'roboAdvice.errors.missingFieldAlert':
    'Please fill at least one field in {0}',
  'roboAdvice.errors.missingSavingPlan': 'Missing input to {0}',
  'roboAdvice.errors.missingSavingPlanAlert': 'Please fill at least one field',
  'roboAdvice.errors.savingPlan': 'Unsuitable saving plan',
  'roboAdvice.errors.savingPlanAlert':
    'The saving plan exceeds the value provided in the financial situation. Please adjust the saving plan or the financial situation values',
  'roboAdvice.errors.internalHolding':
    'Internal holding not allocated correctly to the goal(s)',
  'roboAdvice.errors.internalHoldingAlert':
    'Ensure that you have allocated the internal portfolio to advisory across the goals',
  'roboAdvice.errors.missingAdvisorNotes': 'Missing {0} advisor note',
  'roboAdvice.errors.missingAdvisorNotesAlert':
    'Please fill the advisor note below',
  'roboAdvice.errors.missingReasoningForProductPlatformSelection':
    'Please fill in reasoning for product platform selection',
  'roboAdvice.errors.missingProductPlatform': 'Please select product platform',
  'roboAdvice.errors.assetAllocation': 'Asset allocation deviates from 100 %',
  'roboAdvice.errors.assetAllocationAlert':
    'Please ensure your asset allocation totals 100%',
  'roboAdvice.errors.fundAllocation': 'Fund allocation deviates from 100 %',
  'roboAdvice.errors.fundAllocationAlert':
    'Please ensure your fund allocation totals 100%',
  'roboAdvice.errors.fundNotAvailable':
    '{0} is no longer available for sale and has been removed from the portfolio. Please verify the current portfolio and ensure that it has 100 % allocation',
  'roboAdvice.errors.fundNotAvailableMultiple':
    '{0} and {1} are no longer available for sale and has been removed from the portfolio. Please verify the current portfolio and ensure that it has 100 % allocation',
  'roboAdvice.errors.errorsListHeader': '{0} - {1} error(s)',
  'roboAdvice.errors.missingFieldsAssetSection':
    'Missing fields in asset section',
  'roboAdvice.errors.missingFieldsDebtSection':
    'Missing fields in debt section',
  'roboAdvice.errors.noInstruments': 'Deposit information is incomplete',
  'roboAdvice.errors.noInstrumentsAlert':
    'The initial and/or monthly deposit for the selected instrument(s) is missing',
  'roboAdvice.errors.emptyInstrumentsTable': 'No instruments added',
  'roboAdvice.errors.emptyInstrumentsTableAlert':
    'Please add an instrument and ensure that it has {0} or {1}',
  'roboAdvice.errors.zeroAmountForAdvice':
    'Please ensure that amount for advice is greater than 0',
  'roboAdvice.errors.zeroAmountForAdviceAlert':
    'Please ensure that amount for advice is greater than 0',
  'roboAdvice.errors.missingAssetClassAlert':
    'Please fill the asset class for {0}',
  'roboAdvice.errors.missingAssetClass':
    'Missing asset class for {0} in existing portfolio',
  'roboAdvice.advisory.portfolioMode': 'Portfolio Mode',
  'roboAdvice.advisory.portfolioInterface.modal.header':
    'Switch to Model Portfolio?',
  'roboAdvice.advisory.portfolioInterface.modal.message':
    'Switching portfolio modes will reset all your current work in the custom portfolio. This action cannot be undone. Are you sure you want to proceed?',
  'roboAdvice.advisory.portfolioInterface.modal.confirmButton': 'Switch',
  'roboAdvice.advisory.customPortfolio.assetClassAllocation.riskChangeAlertMessage':
    "The risk score for this goal has been updated, and the asset allocation has been reset accordingly. Please click 'OK' to close this message",
  'roboAdvice.advisory.customPortfolio.fundAllocation.riskChangeAlertMessage':
    "The risk score for this goal has been updated, and the fund allocation has been reset accordingly. Please click 'OK' to close this message",
  'roboAdvice.advisory.customPortfolio.assetClassAllocation.missingInstrumentsInFollowUpSessionAlertMessage':
    'Some of the instruments in the advice you are following up, no longer exists. Please update the custom portfolio and ensure that it has 100% allocation.',
  'roboAdvice.advisory.modelPortfolio.followUpRiskChangedAlert':
    'Risk has changed, and model portfolio is updated',
  'roboAdvice.advisory.modelPortfolio.followUpInstrumentsChangedAlert':
    'The recommended model portfolio has changes since the last advice was given. Please verify the new portfolio',
  'roboAdvice.advisory.riskScoreChangeAlertMessage':
    "Due to your recent action, the risk score has changed. We've updated the advisory page to reflect this. Please review the advisory page for the updated information.",
  'roboAdvice.client.adviceSessionsTable.adviceOwner': 'Advice owner',
  'roboAdvice.client.adviceSessionsTable.followUp': 'Follow-up',
  'roboAdvice.client.followUpAdviceSessionNoAccountsErrorMessage':
    "No client's accounts data found",
  'adviceInformation.advisorNotes.header': 'Advisor notes',
  'adviceInformation.advisorNotes.placeholder': 'Advisor notes',
  'adviceInformation.advisorNotes.infoTooltip': '',
  'purposeAndRisk.advisorNotes.header': 'Advisor notes',
  'purposeAndRisk.advisorNotes.placeholder': 'Advisor notes',
  'purposeAndRisk.advisorNotes.infoTooltip': '',
  'knowledgeAndExperience.advisorNotes.header': 'Advisor notes',
  'knowledgeAndExperience.advisorNotes.placeholder': 'Advisor notes',
  'knowledgeAndExperience.advisorNotes.infoTooltip': '',
  'financialSituation.advisorNotes.header': 'Advisor notes',
  'financialSituation.advisorNotes.placeholder': 'Advisor notes',
  'financialSituation.advisorNotes.infoTooltip': '',
  'financialSituation.amountForAdvice.advisorNotes.header': 'Advisor notes',
  'financialSituation.amountForAdvice.advisorNotes.placeholder':
    'Advisor notes',
  'financialSituation.amountForAdvice.advisorNotes.infoTooltip': '',
  'advisory.customPortfolio.assetClassAllocation.advisorNotes.header':
    'Advisor notes',
  'advisory.customPortfolio.assetClassAllocation.advisorNotes.placeholder':
    'Advisor notes',
  'advisory.customPortfolio.assetClassAllocation.advisorNotes.infoTooltip': '',
  'advisory.customPortfolio.fundAllocation.advisorNotes.header':
    'Advisor notes',
  'advisory.customPortfolio.fundAllocation.advisorNotes.placeholder':
    'Advisor notes',
  'advisory.customPortfolio.fundAllocation.advisorNotes.infoTooltip': '',
  'kyc.advisorNotes.header': 'Advisor notes',
  'kyc.advisorNotes.placeholder': 'Advisor notes',
  'kyc.advisorNotes.infoTooltip': '',
  'orderExecution.orderInformation.advisorNotes.header': 'Advisor notes',
  'orderExecution.orderInformation.advisorNotes.placeholder': 'Advisor notes',
  'orderExecution.orderInformation.advisorNotes.infoTooltip': '',
  'orderExecution.instruments.advisorNotes.header': 'Advisor notes',
  'orderExecution.instruments.advisorNotes.placeholder': 'Advisor notes',
  'orderExecution.instruments.advisorNotes.infoTooltip': '',
  'orderExecution.order.advisorNotes.header': 'Advisor notes',
  'orderExecution.order.advisorNotes.placeholder': 'Advisor notes',
  'orderExecution.order.advisorNotes.infoTooltip': '',
  'orderExecution.orderInformationPage2.advisorNotes.header': 'Advisor notes',
  'orderExecution.orderInformationPage2.advisorNotes.placeholder':
    'Advisor notes',
  'orderExecution.orderInformationPage2.advisorNotes.infoTooltip': '',
  'orderExecution.targetMarketAssessment.advisorNotes.header': 'Advisor notes',
  'orderExecution.targetMarketAssessment.advisorNotes.placeholder':
    'Advisor notes',
  'orderExecution.targetMarketAssessment.advisorNotes.infoTooltip': '',
  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.percentage':
    'Percentage',
  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.monthlyDeposit':
    'Monthly deposit',
  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.initialDeposit':
    'Initial deposit',
  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStep':
    'Numbers should follow incremental steps of {0} - please update',
  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStepInPercentage':
    'Numbers should follow incremental steps of {0} - please update amount value',
  'roboAdvice.errors.errorInvalidIncrementalStepInAssetAllocationAlert':
    'Please ensure your asset allocation values follow incremental steps of {0}%',
  'roboAdvice.advisory.customPortfolio.targetMarketAssessmentError':
    'Portfolio in "{0}" goal include instruments that are not-suitable. Please adjust before proceeding.',
  'roboAdvice.advisory.customPortfolio.targetMarketAssessmentAlert':
    'Please adjust the portfolio(s) before proceeding.',
  'roboAdvice.errors.notSuitableInstrumentsAlert':
    'You have selected instruments that are not suitable and you will not be able to produce the order',
  'roboAdvice.errors.notSuitableInstruments':
    'You have selected instruments that are not suitable. Please adjust instruments before proceeding',
  'roboAdvice.orderExecution.instrumentsTable.targetAssessmentNotFilledWarning':
    'Please fill the information in {0} in order to show the suitability status'
};
