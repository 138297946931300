import create from 'zustand';

import {
  ReadAnalyzeAdvancedSuitabilityConfigResponse,
  ReadAnalyzeAdvancedSuitabilityStatusResponse,
  ReadAnalyzeAdvancedSuitabilityStatusV2Response
} from '../../shared/api/types';
import { PageStatus } from '../../shared/components/useReadDataListener';

type KnowledgeAndExperienceStoreData = {
  advancedSuitabilityConfig: ReadAnalyzeAdvancedSuitabilityConfigResponse | null;
  advancedSuitabilityStatus: Record<
    number,
    ReadAnalyzeAdvancedSuitabilityStatusResponse
  >;
  advancedSuitabilityStatusDataStatuses: Record<number, PageStatus>;
  targetMarketAssessmentData: Record<
    string,
    ReadAnalyzeAdvancedSuitabilityStatusV2Response['data']
  >;
  targetMarketAssessmentDataStatuses: Record<string, PageStatus>;
};

type KnowledgeAndExperienceStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<KnowledgeAndExperienceStoreData>) => void;
  addAdvancedSuitabilityConfigData: (
    data: ReadAnalyzeAdvancedSuitabilityConfigResponse
  ) => void;
  addAdvancedSuitabilityStatusData: (
    data: ReadAnalyzeAdvancedSuitabilityStatusResponse,
    namespaceId: number
  ) => void;
  changeAdvancedSuitabilityStatusDataStatus: (
    status: PageStatus,
    namespaceId: number
  ) => void;
  addTargetMarketAssessmentData: (
    data: ReadAnalyzeAdvancedSuitabilityStatusV2Response['data'],
    goalId: string
  ) => void;
  changeTargetMarketAssessmentDataStatus: (
    status: PageStatus,
    goalId: string
  ) => void;
  resetAdvancedSuitabilityStatusData: () => void;
};

export type KnowledgeAndExperienceStoreState = KnowledgeAndExperienceStoreData &
  KnowledgeAndExperienceStoreApi;

const defaultData: KnowledgeAndExperienceStoreData = {
  advancedSuitabilityConfig: null,
  advancedSuitabilityStatus: {},
  advancedSuitabilityStatusDataStatuses: {},
  targetMarketAssessmentData: {},
  targetMarketAssessmentDataStatuses: {}
};

export const useKnowledgeAndExperienceStore =
  create<KnowledgeAndExperienceStoreState>(set => {
    return {
      ...defaultData,
      reset: () => {
        set(defaultData);
      },
      initialize(initialData) {
        set(
          state => ({
            ...state,
            ...initialData
          }),
          true
        );
      },
      addAdvancedSuitabilityConfigData: data => {
        set(state => ({
          ...state,
          advancedSuitabilityConfig: data
        }));
      },
      addAdvancedSuitabilityStatusData: (data, namespaceId) => {
        set(state => ({
          ...state,
          advancedSuitabilityStatus: {
            ...state.advancedSuitabilityStatus,
            [namespaceId]: data
          }
        }));
      },
      changeAdvancedSuitabilityStatusDataStatus: (status, namespaceId) => {
        set(state => ({
          ...state,
          advancedSuitabilityStatusDataStatuses: {
            ...state.advancedSuitabilityStatusDataStatuses,
            [namespaceId]: status
          }
        }));
      },
      addTargetMarketAssessmentData: (data, goalId) => {
        set(state => ({
          ...state,
          targetMarketAssessmentData: {
            ...state.targetMarketAssessmentData,
            [goalId]: data
          }
        }));
      },
      changeTargetMarketAssessmentDataStatus: (status, goalId) => {
        set(state => ({
          ...state,
          targetMarketAssessmentDataStatuses: {
            ...state.targetMarketAssessmentDataStatuses,
            [goalId]: status
          }
        }));
      },
      resetAdvancedSuitabilityStatusData: () => {
        set(state => ({
          ...state,
          advancedSuitabilityStatus: {},
          advancedSuitabilityStatusDataStatuses: {}
        }));
      }
    };
  });
